/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  vertical-align: baseline;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Custom */
*, *:after, *:before {
  box-sizing: border-box; }

input, textarea, select {
  border: none;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

a, input, textarea, button, select,
a:focus, input:focus, textarea:focus, button:focus, select:focus,
a:active, input:active, textarea:active, button:active, select:active {
  outline: none; }

textarea {
  resize: none; }

img {
  display: block;
  max-width: 100%;
  border-style: none; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit;
    text-decoration: none; }
  aactive {
    color: inherit; }

[hidden] {
  display: none; }

/*PTRootUI-Regular*/
@font-face {
  font-family: 'PT Root UI';
  src: url("../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.eot"), url("../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.svg"), url("../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.woff") format("woff"), url("../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/*PTRootUI-Regular*/
/*PTRootUI-Medium*/
@font-face {
  font-family: 'PT Root UI';
  src: url("../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.eot"), url("../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.svg"), url("../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.woff") format("woff"), url("../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

/*PTRootUI-Medium*/
/*PTRootUI-Bold*/
@font-face {
  font-family: 'PT Root UI';
  src: url("../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.eot"), url("../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.svg"), url("../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.woff") format("woff"), url("../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

/*PTRootUI-Bold*/
/*PTRootUI-Light*/
@font-face {
  font-family: 'PT Root UI';
  src: url("../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.eot"), url("../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.svg"), url("../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.woff") format("woff"), url("../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

/*PTRootUI-Light*/
html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #000000;
  font-weight: 300;
  line-height: 1;
  font-family: "PT Root UI", sans-serif;
  background: #FFFFFF; }

@media (min-width: 992px) and (max-width: 1199px) {
  html,
  body {
    font-size: 18px; } }

*::-webkit-input-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*::-moz-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*:-ms-input-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*:-moz-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

* {
  outline: 0; }

main {
  flex-grow: 1; }

svg, svg path, a, button {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

h1 {
  font-size: 28px;
  margin-bottom: 18px;
  line-height: 32px;
  color: #484848;
  font-weight: 400;
  text-transform: none; }

.h1 {
  font-weight: 500;
  font-size: 45px;
  line-height: 57px; }

@media (max-width: 639px) {
  .h1 {
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 26px; } }

h2, .h2 {
  font-family: "PT Root UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 110%; }

@media (max-width: 1199px) and (min-width: 900px) {
  h2, .h2 {
    font-size: 36px;
    line-height: 110%; } }

@media (max-width: 639px) {
  h2, .h2 {
    font-size: 24px;
    line-height: 120%; } }

a:visited {
  color: inherit; }

img {
  display: inline-block;
  width: auto;
  max-width: 100%;
  height: auto; }

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1170px; }

@media (min-width: 1480px) {
  .container {
    max-width: 1370px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .container {
    max-width: 890px; } }

@media (max-width: 959px) and (min-width: 640px) {
  .container {
    max-width: 610px; } }

@media (max-width: 639px) {
  .container {
    max-width: 100%;
    padding: 0 30px; } }

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap; }

.content-box {
  line-height: 1.4; }
  .content-box h2 {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 28px; }
  .content-box p {
    margin-bottom: 15px; }
  .content-box img {
    margin-bottom: 15px; }
  .content-box b, .content-box strong {
    font-weight: 700; }
  .content-box i, .content-box em {
    font-style: italic; }
  .content-box h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px; }
  .content-box h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px; }
  .content-box ul {
    font-size: 20px;
    line-height: 140%;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #323232; }
    .content-box ul li {
      padding-left: 30px;
      margin-bottom: 22px;
      position: relative; }
      .content-box ul li:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #F90936;
        display: block;
        position: absolute;
        left: 0;
        top: 10px; }
  .content-box ol {
    list-style: decimal;
    padding-left: 20px;
    margin-bottom: 15px; }
  .content-box blockquote {
    border: dotted #666 1px;
    border-left: solid #065888 4px;
    margin: 10px 40px;
    padding: 15px;
    color: #333;
    font-style: italic;
    font-size: 14px;
    background: #fcfcfc; }
    .content-box blockquote .quote a {
      color: #0f5cac;
      font-size: 12px; }
    .content-box blockquote .quote ul {
      list-style: none; }
  .content-box table thead th {
    border-bottom: 2px solid #065888;
    padding: 3px 10px; }
  .content-box table tbody td {
    border: 1px solid #ccc;
    padding: 3px 10px; }

a:hover {
  color: #065888; }

.btn {
  border: 0;
  padding: 18px 23px 17px;
  width: auto;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border-radius: 20px;
  cursor: pointer; }
  .btn-arr {
    position: relative;
    padding-right: 49px; }
    .btn-arr svg {
      position: absolute;
      right: 23px;
      top: 50%;
      transform: translateY(-50%); }
  .btn-red {
    background: #F90936;
    color: #FFFFFF;
    transition: 0.3s; }
    .btn-red:visited {
      color: #FFFFFF; }
    .btn-red:hover {
      background: #065888;
      color: #FFFFFF;
      transition: 0.3s; }
      .btn-red:hover svg path {
        fill: #FFFFFF; }
  .btn-gray_bord {
    border: 2px solid rgba(6, 88, 136, 0.15);
    border-radius: 20px;
    color: #F90936;
    transition: 0.3s; }
    .btn-gray_bord:visited {
      color: #F90936; }
    .btn-gray_bord:hover {
      border-color: #065888;
      color: #065888;
      transition: 0.3s; }
      .btn-gray_bord:hover svg path {
        fill: #065888; }
  .btn-white_bord {
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    color: #FFFFFF;
    transition: 0.3s; }
    .btn-white_bord:visited {
      color: #FFFFFF; }
    .btn-white_bord:hover {
      border-color: #F90936;
      color: #F90936;
      transition: 0.3s; }
      .btn-white_bord:hover svg path {
        fill: #F90936; }
  .btn-white {
    background: #FFFFFF;
    color: #F90936;
    transition: 0.3s; }
    .btn-white:visited {
      color: #F90936; }
    .btn-white:hover {
      background: #F90936;
      color: #FFFFFF;
      transition: 0.3s; }
      .btn-white:hover svg path {
        fill: #FFFFFF; }

@media (max-width: 639px) {
  h2 {
    font-size: 16px;
    line-height: 19px; }
  .content-box h2 {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 19px; }
  .content-box p {
    font-size: 14px;
    line-height: 18px; } }

input.wpcf7-not-valid {
  border-color: #f00 !important; }

input.error, textarea.error {
  border-color: #f00; }

input[type="checkbox"].error, textarea[type="checkbox"].error {
  box-shadow: 0px 0px 1px 1px #f00; }

.mb0 {
  margin-bottom: 0 !important; }

.slick-arrow {
  cursor: pointer; }

.top_section {
  background: #065888;
  padding: 37px 0 31px; }
  .top_section-col {
    width: 100%;
    max-width: 50%;
    padding: 0 15px;
    margin-bottom: 10px; }
  .top_section-txt {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #FFFFFF; }
  .top_section-txt2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #FFFFFF;
    margin-top: 12px; }
    .top_section-txt2 p {
      margin: 0; }
  .top_section-ttl {
    color: #FFFFFF;
    max-width: 600px; }
  .top_section.simp {
    padding: 37px 0; }
    .top_section.simp .top_section-ttl {
      margin-bottom: 0; }

@media (max-width: 1199px) {
  .top_section-col {
    max-width: none; } }

@media (max-width: 639px) {
  .top_section-txt {
    font-size: 16px;
    line-height: 155%; } }

.demo-section {
  background: #F2F4F6;
  overflow: hidden; }
  .demo-section-bl {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 50px;
    position: relative;
    height: 100%; }
    .demo-section-bl:before {
      content: '';
      background: url("../img/demo-info.jpg") no-repeat;
      background-size: cover;
      display: block;
      height: 100%;
      width: 720px;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 1; }
  .demo-section-btn {
    position: relative;
    z-index: 2; }
  .demo-section-left {
    padding: 43px 15px;
    width: 100%;
    max-width: 50%;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%; }
  .demo-section-right {
    width: 100%;
    max-width: 50%; }
  .demo-section-txt {
    max-width: 620px; }

@media (max-width: 1199px) and (min-width: 960px) {
  .demo-section-left {
    max-width: none; }
  .demo-section-txt {
    max-width: none; }
  .demo-section-right {
    max-width: none; }
  .demo-section-bl:before {
    left: -35px;
    width: calc(100% + 70px); } }

@media (max-width: 959px) and (min-width: 640px) {
  .demo-section-left {
    max-width: none; }
  .demo-section-txt {
    max-width: none; }
  .demo-section-right {
    max-width: none; }
  .demo-section-bl:before {
    left: -35px;
    width: calc(100% + 70px); } }

@media (max-width: 639px) {
  .demo-section-left {
    max-width: none; }
  .demo-section-txt {
    max-width: none; }
    .demo-section-txt.content-box > * {
      font-size: 20px;
      line-height: 140%; }
    .demo-section-txt.content-box h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%; }
  .demo-section-right {
    max-width: none; }
  .demo-section-bl {
    padding: 100px 15px; }
    .demo-section-bl:before {
      left: -35px;
      width: calc(100% + 70px); } }

.sidebar-menu ul > li {
  margin-bottom: 27px; }
  .sidebar-menu ul > li > a {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #323232;
    display: inline-block; }
  .sidebar-menu ul > li.current > a {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 2px 7px;
    color: #065888; }

@media (max-width: 1199px) and (min-width: 960px) {
  .sidebar {
    border-bottom: 1px solid #fff; }
    .sidebar-menu ul {
      display: flex;
      flex-wrap: wrap; }
      .sidebar-menu ul li {
        width: 100%;
        max-width: 50%; } }

.form-input, .form-textarea {
  margin-bottom: 25px; }
  .form-input input, .form-input textarea, .form-textarea input, .form-textarea textarea {
    width: 100%;
    padding: 13px 20px;
    border-radius: 0;
    border: 1px solid #fff; }
  .form-input textarea, .form-textarea textarea {
    height: 205px; }
  .form-input .wpcf7-not-valid-tip, .form-textarea .wpcf7-not-valid-tip {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    background: #f00;
    display: inline-block;
    transform: translateY(-2px);
    padding: 1px 4px 3px; }

.form-check label input {
  display: none; }

.form-check label span.wpcf7-list-item-label {
  color: #fff;
  cursor: pointer;
  padding-left: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 129.5%;
  margin-bottom: 25px;
  display: inline-block;
  position: relative; }
  .form-check label span.wpcf7-list-item-label:before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    border: 1px solid #E2E4E6;
    box-sizing: border-box;
    border-radius: 2px;
    width: 12px;
    height: 12px; }
  .form-check label span.wpcf7-list-item-label a {
    text-decoration: underline; }
    .form-check label span.wpcf7-list-item-label a:hover {
      color: #F90936; }

.form-check label input:checked + span.wpcf7-list-item-label:before {
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L3 7L1 4' stroke='%23065888' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  -webkit-background-size: 6px 6px;
  background-size: 6px 6px;
  background-position: 50% 50%; }

.form-check .wpcf7-list-item {
  margin-left: 0; }

.form-file {
  margin-bottom: 17px; }
  .form-file input {
    color: #fff; }
  .form-file span {
    color: #fff;
    margin-bottom: 10px;
    display: block; }

.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
  display: none !important; }

.box-modal {
  max-width: 540px;
  margin: 50px auto; }

.modal {
  background: #065888;
  padding: 50px;
  position: relative; }
  .modal-close {
    color: #fff;
    position: absolute;
    top: 14px;
    right: 16px;
    font-size: 40px;
    line-height: 23px;
    height: 23px;
    cursor: pointer; }
  .modal-form-ttl {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #fff;
    margin-bottom: 44px; }

.d-none {
  display: none; }

#sb_instagram {
  margin: 0 -5px;
  display: flex;
  flex-wrap: wrap; }

#sb_instagram .sb_instagram_header {
  padding: 5px !important;
  width: 33.3%;
  margin: 0 !important; }

#sb_instagram .sb_instagram_header .sbi_header_text {
  display: none; }

#sb_instagram .sb_instagram_header .sbi_header_img {
  float: none;
  margin: 0 !important;
  width: 95px;
  height: 95px;
  border-radius: 0; }

.sbi_header_hashtag_icon {
  border-radius: 0; }

/*header*/
.h-logo {
  display: block;
  padding-right: 51px;
  padding-top: 37px;
  padding-left: 15px;
  padding-bottom: 8px; }

.h-cont {
  flex: 1;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 15px;
  padding-bottom: 8px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-wrap: wrap; }

.h-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }

.h-soc {
  display: flex;
  flex-wrap: wrap;
  margin-right: 11px;
  margin-bottom: 12px; }
  .h-soc a {
    margin-right: 15px;
    margin-bottom: 10px; }

.h-info {
  display: flex;
  flex-wrap: wrap;
  padding-right: 15px;
  flex: 1; }

.h-phn {
  margin-top: 4px;
  margin-bottom: 14px; }
  .h-phn a {
    color: #FFFFFF; }

.h-menu {
  width: 100%; }
  .h-menu .sub-menu {
    z-index: -9999;
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute;
    background: #fff;
    padding: 10px 6px 0;
    border-radius: 5px;
    display: block;
    pointer-events: none;
    width: max-content; }
    .h-menu .sub-menu li {
      padding: 0 6px 10px; }
      .h-menu .sub-menu li a {
        color: #065888;
        line-height: 130%; }
      .h-menu .sub-menu li.current-menu-item a {
        color: #F90936; }
  .h-menu ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -15px; }
    .h-menu ul li {
      padding: 0 15px 20px; }
      .h-menu ul li:first-child .sub-menu {
        left: 30px; }
      .h-menu ul li:last-child .sub-menu {
        left: auto;
        right: 0; }
      .h-menu ul li.menu-item-has-children {
        position: relative;
        padding-right: 20px; }
        .h-menu ul li.menu-item-has-children:before, .h-menu ul li.menu-item-has-children .click {
          content: '';
          display: block;
          position: absolute;
          width: 7px;
          height: 7px;
          right: 0;
          top: 8px; }
        .h-menu ul li.menu-item-has-children:before {
          border-bottom: 2px solid #FFFFFF;
          border-right: 2px solid #FFFFFF;
          transform: rotate(45deg);
          z-index: 2; }
        .h-menu ul li.menu-item-has-children .click {
          z-index: 3;
          width: 13px;
          height: 13px;
          right: -2px;
          top: 6px; }
      .h-menu ul li a {
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        padding: 0 6px;
        display: inline-block; }
      .h-menu ul li.current-menu-item a, .h-menu ul li.current-menu-parent a {
        background: #FFFFFF;
        color: #065888;
        border-radius: 5px; }
      .h-menu ul li.current-menu-item.menu-item-has-children:before, .h-menu ul li.current-menu-parent.menu-item-has-children:before {
        border-color: #065888; }
      .h-menu ul li.hovered .sub-menu {
        z-index: 1;
        opacity: 1;
        transition: opacity 0.3s;
        pointer-events: all; }

.h-auth {
  margin-bottom: 24px; }
  .h-auth a {
    padding: 4px 0px 5px 35px;
    display: inline-block;
    color: #FFFFFF;
    background: url("../img/auth.svg") no-repeat 0 50%; }

.h-btn {
  display: none;
  padding: 7px 15px 0; }
  .h-btn button {
    background: #D8E9F3;
    border-radius: 20px;
    width: 48px;
    padding: 15px 12px;
    border: 0;
    margin-left: 15px; }
    .h-btn button span {
      background: #065888;
      border-radius: 2px;
      width: 100%;
      height: 2px;
      display: block;
      margin: 5px 0; }
      .h-btn button span:first-child {
        margin-top: 0; }
      .h-btn button span:last-child {
        margin-bottom: 0; }

.h-mob {
  display: none; }

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .header.static {
    background: #F2F4F6;
    position: static; }
    .header.static .h-logo {
      background: #FFFFFF;
      position: relative; }
      .header.static .h-logo svg path[fill="white"] {
        fill: #000000; }
      .header.static .h-logo:before {
        content: '';
        position: absolute;
        right: 0;
        width: 50vw;
        height: 100%;
        background: #FFFFFF;
        z-index: 1;
        top: 0; }
      .header.static .h-logo svg {
        position: relative;
        z-index: 2; }
    .header.static .h-phn a, .header.static .h-auth a, .header.static .h-menu ul li a {
      color: #000000; }
    .header.static .h-auth a {
      background: url("../img/auth_white.svg") no-repeat 0 50%; }
    .header.static .h-menu ul li.menu-item-has-children:before {
      border-color: #065888; }
    .header.static .h-menu .sub-menu li a {
      color: #065888; }
    .header.static .h-menu .sub-menu li.current-menu-item a {
      color: #F90936; }
    .header.static .h-soc a svg path {
      fill: #B0B1B2; }
    .header.static path#path4684 {
      fill: #f2f4f6 !important; }
    .header.static path#path4680 {
      fill: #b0b1b2 !important; }

/*header*/
.video-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center; }
  .video-back video {
    min-height: calc(100% + 172px); }

/*main page*/
.main-first {
  position: relative;
  padding-top: 161px;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 0;
  padding-bottom: 60px; }
  .main-first:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #323232;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block; }
  .main-first .container {
    position: relative;
    z-index: 3; }
  .main-first-bl {
    max-width: 860px; }
  .main-first-ttl {
    font-weight: 500;
    font-size: 45px;
    line-height: 57px;
    color: #FFFFFF;
    margin-bottom: 38px; }

.main-numbers {
  overflow: hidden; }
  .main-numbers-col {
    padding: 0 15px 30px;
    width: 100%;
    max-width: 335px;
    overflow: hidden; }
  .main-numbers-left {
    padding: 35px 15px 14px;
    width: 100%;
    max-width: 1005px; }
  .main-numbers-right {
    padding: 0 15px;
    width: 100%;
    flex: 1;
    position: relative; }
  .main-numbers-bl-ttl {
    font-size: 54px;
    line-height: 68px;
    display: flex;
    align-items: center;
    color: #F90936;
    font-weight: 600; }
  .main-numbers-bl-txt {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #323232; }
  .main-numbers-one {
    padding: 35px 50px 43px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%; }
    .main-numbers-one:before {
      content: '';
      width: 385px;
      position: absolute;
      background: url(../img/one-back.jpg) no-repeat 0% 0%;
      background-size: cover;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1; }
    .main-numbers-one-ttl {
      font-style: normal;
      font-weight: normal;
      font-size: 54px;
      line-height: 68px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      position: relative;
      z-index: 2; }
    .main-numbers-one-txt {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #FFFFFF;
      position: relative;
      z-index: 2; }

.main-hot {
  background: #F2F4F6; }
  .main-hot-slider {
    position: relative; }
    .main-hot-slider .slick-arrow {
      position: absolute;
      top: 48px;
      cursor: pointer;
      z-index: 3; }
      .main-hot-slider .slick-arrow.arrow-prev {
        left: 41.3%; }
      .main-hot-slider .slick-arrow.arrow-next {
        left: 45.6%; }
  .main-hot-row {
    display: flex;
    flex-wrap: wrap; }
  .main-hot-slide-left {
    width: 100%;
    max-width: 720px;
    padding: 46px 0;
    display: flex;
    flex-direction: column; }
  .main-hot-slide-top {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #B0B1B3;
    margin-bottom: 30px; }
  .main-hot-slide-txt {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #065888;
    flex: 1;
    padding-right: 25px; }
  .main-hot-slide-right {
    position: relative;
    flex: 1; }

.main-revs {
  overflow: hidden;
  background: #fff; }
  .main-revs-left {
    position: relative;
    width: 100%;
    max-width: 350px;
    padding: 39px 15px; }
    .main-revs-left:before {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      left: -35px;
      height: 100%;
      width: calc(100% + 35px);
      background: #065888; }
  .main-revs-ttl {
    position: relative;
    z-index: 2;
    color: #FFFFFF; }
  .main-revs-right {
    flex: 1;
    max-width: calc(100% - 350px); }
    .main-revs-right-top {
      background: #FFFFFF;
      padding: 74px 0 34px;
      overflow: hidden;
      margin-right: -34px; }
    .main-revs-right-btm {
      padding: 46px 28px 46px 51px;
      position: relative; }
  .main-revs-slider {
    max-width: 236px; }
    .main-revs-slider .slick-list.draggable {
      overflow: visible; }
    .main-revs-slider_text .slick-arrow {
      position: absolute;
      top: 0;
      cursor: pointer;
      z-index: 2; }
      .main-revs-slider_text .slick-arrow.arrow-prev {
        right: 5%; }
      .main-revs-slider_text .slick-arrow.arrow-next {
        right: -1%; }
  .main-revs-slide {
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .main-revs-slide_text-top {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #B0B1B3;
      margin-bottom: 30px; }
    .main-revs-slide_text-txt {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: #065888;
      max-width: 966px;
      margin-bottom: 37px;
      min-height: 184px; }
    .main-revs-slide_text-file a {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #065888;
      display: flex;
      align-items: center; }
      .main-revs-slide_text-file a svg {
        margin-right: 15px; }

.main-news {
  background: #E2E4E6;
  padding: 46px 0 51px; }
  .main-news-ttl {
    margin-bottom: 32px;
    flex: 1; }
  .main-news-top {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap; }
  .main-news-more {
    padding-top: 6px; }
    .main-news-more a {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #F90936; }
      .main-news-more a svg {
        margin-left: 8px; }
  .main-news-row {
    display: flex;
    flex-wrap: wrap; }
  .main-news-bl {
    background: #fff;
    display: block;
    padding: 43px 50px;
    position: relative; }
    .main-news-bl-img {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: block;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      transition: 0.3s; }
      .main-news-bl-img:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: #065888;
        opacity: 0.6; }
    .main-news-bl-date {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: #B0B1B3;
      margin-bottom: 2px;
      position: relative;
      z-index: 2; }
    .main-news-bl-ttl {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      display: flex;
      align-items: flex-end;
      color: #323232;
      display: block;
      min-height: 110px;
      margin-bottom: 25px;
      position: relative;
      z-index: 2; }
    .main-news-bl-cat {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #065888;
      position: relative;
      z-index: 2; }
      .main-news-bl-cat:visited {
        color: #065888; }
    .main-news-bl:hover {
      background: #065888; }
      .main-news-bl:hover .main-news-bl-img {
        opacity: 1;
        transition: 0.3s; }
      .main-news-bl:hover .main-news-bl-date, .main-news-bl:hover .main-news-bl-ttl, .main-news-bl:hover .main-news-bl-cat {
        color: #fff; }
  .main-news-col {
    width: 50%; }
    .main-news-col:first-child .main-news-bl {
      border-right: 2px solid #e2e4e6; }

/*main page*/
/*page_about*/
.page_about-first {
  position: relative;
  padding-top: 161px;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 0;
  padding-bottom: 60px; }
  .page_about-first:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #323232;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block; }
  .page_about-first .container {
    position: relative;
    z-index: 2; }
  .page_about-first-bl {
    max-width: 860px; }
  .page_about-first-ttl {
    font-weight: 500;
    font-size: 45px;
    line-height: 57px;
    color: #FFFFFF;
    margin-bottom: 28px; }
  .page_about-first-txt {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #FFFFFF;
    max-width: 650px;
    margin-bottom: 28px; }

.page_about-hist {
  padding: 38px 0 31px; }
  .page_about-hist-left {
    padding: 0 15px;
    width: 100%;
    max-width: 670px; }
  .page_about-hist-right {
    padding: 0 15px;
    width: 100%;
    max-width: 700px; }
  .page_about-hist-ttl {
    margin-bottom: 20px; }
  .page_about-hist-cont {
    padding-top: 3px;
    margin-bottom: 20px; }
    .page_about-hist-cont-ttl {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: #065888;
      margin-bottom: 48px; }
    .page_about-hist-cont-txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: #323232; }

.page_about-dates {
  background: #F2F4F6;
  padding: 36px 0 18px;
  overflow: hidden; }
  .page_about-dates-slider {
    max-width: 1005px;
    position: static;
    z-index: 2; }
    .page_about-dates-slider_wrap {
      position: relative; }
      .page_about-dates-slider_wrap:before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        z-index: 1;
        display: block;
        background: #B0B1B3;
        top: 95px; }
    .page_about-dates-slider > * {
      z-index: 2; }
    .page_about-dates-slider .slick-arrow {
      position: absolute;
      top: 12px;
      z-index: 3;
      cursor: pointer; }
      .page_about-dates-slider .slick-arrow.arrow-prev {
        right: 50px; }
      .page_about-dates-slider .slick-arrow.arrow-next {
        right: 0%; }
    .page_about-dates-slider .slick-list.draggable {
      overflow: visible; }
  .page_about-dates-bl-date {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #B0B1B3;
    height: 57px; }
  .page_about-dates-bl-circ {
    width: 23px;
    height: 23px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .page_about-dates-bl-circ:before {
      content: '';
      display: block;
      border-radius: 50%;
      width: 11px;
      height: 11px;
      background: #B0B1B3; }
  .page_about-dates-bl-txt {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    display: flex;
    color: #323232;
    margin-bottom: 69px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
  .page_about-dates-bl-quan {
    font-weight: 500;
    font-size: 36px;
    line-height: 110%;
    display: flex;
    align-items: center;
    color: #B0B1B3; }
  .page_about-dates-bl-txt2 {
    font-weight: 500;
    font-size: 11px;
    line-height: 160%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #B0B1B3; }
  .page_about-dates .slick-current .page_about-dates-bl-date {
    font-size: 45px;
    line-height: 57px;
    color: #F90936; }
  .page_about-dates .slick-current .page_about-dates-bl-circ:before {
    width: 23px;
    height: 23px;
    background: #F90936; }

.page_about-info {
  overflow: hidden; }
  .page_about-info-ttl {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #B0B1B3;
    margin-bottom: 43px; }
  .page_about-info-left {
    padding: 0 15px;
    padding-top: 44px;
    width: 100%;
    max-width: 670px; }
  .page_about-info-right {
    padding: 0 15px;
    width: 100%;
    max-width: 700px; }
  .page_about-info-directors {
    display: flex;
    margin: 0 -15px;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 540px; }
    .page_about-info-directors-bl {
      padding: 0 15px;
      margin-bottom: 47px; }
      .page_about-info-directors-bl-img {
        margin-bottom: 12px;
        overflow: hidden;
        border-radius: 50%;
        max-width: 173px;
        width: 100%;
        height: 173px; }
      .page_about-info-directors-bl-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #065888; }
  .page_about-info-bl {
    padding: 42px 50px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 627px;
    position: relative;
    height: 100%; }
    .page_about-info-bl:before {
      content: '';
      width: 720px;
      height: 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      display: block;
      background: url("../img/about-info.jpg");
      -webkit-background-size: cover;
      background-size: cover; }
    .page_about-info-bl-ttl {
      font-weight: 500;
      font-size: 36px;
      line-height: 110%;
      color: #FFFFFF;
      position: relative;
      z-index: 2;
      margin-bottom: 26px; }
    .page_about-info-bl-txt {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #FFFFFF;
      position: relative;
      z-index: 2; }

.page_about-empl {
  padding: 50px 0 0;
  background: #F2F4F6; }
  .page_about-empl-bl {
    padding: 0 15px;
    margin-bottom: 47px;
    width: 100%;
    max-width: 335px; }
    .page_about-empl-bl-img {
      margin-bottom: 12px;
      overflow: hidden;
      border-radius: 50%;
      max-width: 173px;
      width: 100%;
      height: 173px; }
    .page_about-empl-bl-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #065888;
      margin-bottom: 9px; }
    .page_about-empl-bl-pos {
      font-weight: 500;
      font-size: 11px;
      line-height: 160%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #B0B1B3; }

.page_about-sert {
  overflow: hidden; }
  .page_about-sert-left {
    padding: 50px 15px 48px;
    max-width: 1005px;
    width: 100%; }
  .page_about-sert-right {
    padding: 0 15px;
    width: 100%;
    max-width: 365px; }
    .page_about-sert-right-bl {
      padding: 42px 50px;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      height: 100%; }
      .page_about-sert-right-bl:before {
        content: '';
        width: 385px;
        height: 100%;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        display: block;
        background: #065888; }
  .page_about-sert-ttl {
    position: relative;
    z-index: 2;
    font-weight: 500;
    color: #FFFFFF; }
  .page_about-sert-arrs {
    position: relative;
    z-index: 2;
    display: flex;
    width: 76px;
    justify-content: space-between; }
    .page_about-sert-arrs button {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer; }
  .page_about-sert-slide {
    padding-right: 15px; }

.page_about-partn {
  background: #F2F4F6;
  overflow: hidden; }
  .page_about-partn-left {
    padding: 43px 15px;
    width: 100%;
    max-width: 671px; }
  .page_about-partn-right {
    padding: 0 15px;
    width: 100%;
    max-width: 699px; }
  .page_about-partn-txt {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    width: 100%;
    max-width: 600px; }
  .page_about-partn-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .page_about-partn-btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 720px;
      height: 100%;
      background: #D8E9F3;
      display: block;
      z-index: 1; }
    .page_about-partn-btn a {
      position: relative;
      z-index: 2; }

.page_about-team {
  background: #F2F4F6;
  overflow: hidden; }
  .page_about-team-left {
    padding: 0 15px;
    width: 100%;
    max-width: 700px; }
  .page_about-team-right {
    padding: 46px 15px;
    width: 100%;
    max-width: 670px; }
  .page_about-team-img {
    position: relative; }
    .page_about-team-img:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 540px;
      width: 720px;
      background: url("../img/about-team.jpg");
      -webkit-background-size: cover;
      background-size: cover;
      left: -50px; }
  .page_about-team-bl {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    min-height: 446px; }
  .page_about-team-ttl {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #B0B1B3;
    margin-bottom: 30px; }
  .page_about-team-txt {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #065888;
    margin-bottom: 20px;
    flex: 1; }

/*page_about*/
/*page_serv*/
.page_serv {
  background: #F2F4F6; }
  .page_serv-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2px; }
    .page_serv-row-left {
      width: 100%;
      max-width: 50%;
      position: relative; }
    .page_serv-row-img {
      height: 480px;
      width: calc(100% + 50px);
      margin-left: -50px; }
    .page_serv-row-right {
      max-width: 50%;
      width: 100%;
      padding: 50px 0 43px 44px;
      display: flex;
      flex-direction: column; }
    .page_serv-row-num {
      position: absolute;
      z-index: 2;
      font-size: 130px;
      line-height: 164px;
      color: #FFFFFF;
      top: 12px;
      left: 0px; }
    .page_serv-row-ttl {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: #065888;
      margin-bottom: 26px; }
    .page_serv-row-txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: #323232;
      flex: 1;
      padding-bottom: 20px; }
    .page_serv-row-btn a {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #F90936; }

/*page_serv*/
/*page_w_sys*/
.page_w_sys-numbers {
  padding: 35px 0; }
  .page_w_sys-numbers-col {
    padding: 0 15px 10px;
    width: 100%;
    max-width: 335px;
    overflow: hidden; }
  .page_w_sys-numbers-bl-ttl {
    font-size: 54px;
    line-height: 68px;
    display: flex;
    align-items: center;
    color: #F90936; }
  .page_w_sys-numbers-bl-txt {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #323232; }
  .page_w_sys-numbers-one {
    padding: 35px 50px 43px;
    display: flex;
    flex-direction: column;
    height: 357px;
    justify-content: space-between;
    width: 385px;
    position: absolute;
    background: url("../img/one-back.jpg") no-repeat 0% 0%;
    -webkit-background-size: cover;
    background-size: cover; }
    .page_w_sys-numbers-one-ttl {
      font-style: normal;
      font-weight: normal;
      font-size: 54px;
      line-height: 68px;
      display: flex;
      align-items: center;
      color: #FFFFFF; }
    .page_w_sys-numbers-one-txt {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #FFFFFF; }

.page_w_sys-team {
  background: #F2F4F6;
  overflow: hidden; }
  .page_w_sys-team-left {
    padding: 0 15px;
    width: 100%;
    max-width: 700px; }
  .page_w_sys-team-right {
    padding: 46px 15px;
    width: 100%;
    max-width: 670px; }
  .page_w_sys-team-img {
    position: relative; }
    .page_w_sys-team-img:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 633px;
      width: 720px;
      background: url("../img/system_about.jpg");
      -webkit-background-size: cover;
      background-size: cover;
      left: -50px; }
  .page_w_sys-team-bl {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    min-height: 446px; }
  .page_w_sys-team-ttl {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #B0B1B3;
    margin-bottom: 30px; }
  .page_w_sys-team-txt {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #065888;
    margin-bottom: 20px;
    flex: 1; }

.page_w_sys-sph {
  padding: 39px 0 50px; }
  .page_w_sys-sph-ttl {
    margin-bottom: 47px; }
  .page_w_sys-sph .row {
    margin: 1px; }
  .page_w_sys-sph-bl {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 333px;
    padding: 41px 48px; }
    .page_w_sys-sph-bl-img {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      top: 0;
      left: 0;
      background: #065888;
      -webkit-background-size: cover;
      background-size: cover;
      transition: 0.3s; }
    .page_w_sys-sph-bl-ttl {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: #065888;
      position: relative;
      flex: 1;
      z-index: 2;
      transition: 0.3s; }
    .page_w_sys-sph-bl:hover .page_w_sys-sph-bl-img {
      opacity: 1;
      transition: 0.3s; }
    .page_w_sys-sph-bl:hover .page_w_sys-sph-bl-ttl {
      color: #FFFFFF;
      transition: 0.3s; }
  .page_w_sys-sph-col {
    width: 100%;
    max-width: 25%;
    background: #F2F4F6;
    border: 1px solid #fff;
    margin: -1px; }
    .page_w_sys-sph-col.big {
      max-width: 50%; }
      .page_w_sys-sph-col.big .page_w_sys-sph-bl {
        background: #065888; }
        .page_w_sys-sph-col.big .page_w_sys-sph-bl-ttl {
          color: #FFFFFF; }

.page_w_sys-prod {
  background: #F2F4F6; }
  .page_w_sys-prod-col {
    width: 100%;
    max-width: 50%;
    padding: 50px 15px; }
    .page_w_sys-prod-col:first-child {
      border-right: 1px solid #FFFFFF; }
    .page_w_sys-prod-col:last-child {
      padding-left: 50px; }
  .page_w_sys-prod-bl-img {
    margin-bottom: 32px; }
  .page_w_sys-prod-bl-txt {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 109px;
    max-width: 619px; }

.page_w_sys-info {
  padding: 42px 0 50px; }
  .page_w_sys-info-left {
    padding: 0 15px;
    width: 100%;
    max-width: 50%; }
  .page_w_sys-info-right {
    padding: 0 15px;
    width: 100%;
    max-width: 50%; }
  .page_w_sys-info-top {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #065888;
    margin-bottom: 26px; }
  .page_w_sys-info-txt {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    color: #323232;
    margin-bottom: 43px; }
  .page_w_sys-info-ttl {
    margin-bottom: 26px;
    max-width: 620px; }

.page_w_sys-cats {
  background: #F2F4F6;
  padding: 50px 0 23px; }
  .page_w_sys-cats-col {
    max-width: 25%;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px; }
  .page_w_sys-cats-bl {
    width: 100%;
    max-width: 298px;
    display: block; }
    .page_w_sys-cats-bl-img {
      margin-bottom: 24px;
      display: block;
      text-align: center;
      font-size: 0; }
    .page_w_sys-cats-bl-txt {
      font-size: 20px;
      line-height: 140%;
      color: #323232;
      display: block; }

.page_w_sys-goto {
  background: #065888;
  padding: 43px 0; }
  .page_w_sys-goto-link {
    font-size: 16px;
    line-height: 155%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF; }
    .page_w_sys-goto-link:visited {
      color: #FFFFFF; }
    .page_w_sys-goto-link:hover {
      color: #F90936; }
      .page_w_sys-goto-link:hover svg path {
        fill: #F90936; }

.page_w_sys-why {
  background: #F2F4F6;
  padding-top: 42px; }
  .page_w_sys-why-top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 23px;
    align-items: center; }
  .page_w_sys-why-ttl {
    flex: 1;
    margin-bottom: 20px; }
  .page_w_sys-why-more {
    margin-bottom: 20px; }
    .page_w_sys-why-more a {
      font-size: 16px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #F90936; }
      .page_w_sys-why-more a:hover {
        color: #065888; }
        .page_w_sys-why-more a:hover svg path {
          fill: #065888; }
  .page_w_sys-why-col {
    width: 100%;
    max-width: 456px;
    padding: 0 15px 49px; }
  .page_w_sys-why-bl {
    padding-top: 36px;
    border-top: 2px solid #E2E4E6; }
    .page_w_sys-why-bl-ttl {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: #065888;
      margin-bottom: 23px; }
    .page_w_sys-why-bl-txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: #323232; }

/*page_w_sys*/
/*page_w_host*/
.page_w_host .demo-section {
  background: #FFFFFF; }

.page_w_host-adv {
  padding: 42px 0;
  background: #F2F4F6; }
  .page_w_host-adv-ttl {
    margin-bottom: 43px; }
  .page_w_host-adv-right {
    max-width: 923px;
    width: 100%;
    padding: 0 15px; }
  .page_w_host-adv-left {
    flex: 1;
    padding: 0 15px; }
  .page_w_host-adv-bl {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 46px 50px; }
    .page_w_host-adv-bl-ttl {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #B0B1B3;
      margin-bottom: 30px; }
    .page_w_host-adv-bl-txt {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: #065888;
      margin-bottom: 20px;
      flex: 1; }
      .page_w_host-adv-bl-txt ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%; }
        .page_w_host-adv-bl-txt ul li {
          width: 50%; }
    .page_w_host-adv-bl.unstand {
      background: none;
      padding-left: 0;
      padding-right: 0; }
      .page_w_host-adv-bl.unstand .page_w_host-adv-bl-txt ul li {
        width: 100%; }
  .page_w_host-adv-btm {
    width: 100%;
    padding: 0 15px;
    border-top: 2px solid #F2F4F6; }
    .page_w_host-adv-btm .content-box ul li:before {
      background: none;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1C7 0.447754 6.55228 0 6 0C5.44772 0 5 0.447754 5 1V5H1C0.447723 5 0 5.44775 0 6C0 6.55225 0.447723 7 1 7H5V11C5 11.5522 5.44772 12 6 12C6.55228 12 7 11.5522 7 11V7H11C11.5523 7 12 6.55225 12 6C12 5.44775 11.5523 5 11 5H7V1Z' fill='%2324BE08'/%3E%3C/svg%3E%0A");
      -webkit-background-size: cover;
      background-size: cover;
      width: 12px;
      height: 12px;
      top: 8px; }

.page_w_host-sph {
  padding: 39px 0 50px; }
  .page_w_host-sph-ttl {
    margin-bottom: 47px; }
  .page_w_host-sph .row {
    margin: 1px; }
  .page_w_host-sph-bl {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 333px;
    padding: 41px 48px; }
    .page_w_host-sph-bl-img {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      top: 0;
      left: 0;
      background: #065888;
      -webkit-background-size: cover;
      background-size: cover;
      transition: 0.3s; }
    .page_w_host-sph-bl-ttl {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: #065888;
      position: relative;
      flex: 1;
      z-index: 2;
      transition: 0.3s; }
    .page_w_host-sph-bl-ico {
      background: #FFFFFF;
      border-radius: 10px;
      width: 60px;
      height: 60px;
      padding: 10px;
      position: relative;
      z-index: 2; }
    .page_w_host-sph-bl:hover .page_w_host-sph-bl-img {
      opacity: 1;
      transition: 0.3s; }
    .page_w_host-sph-bl:hover .page_w_host-sph-bl-ttl {
      color: #FFFFFF;
      transition: 0.3s; }
  .page_w_host-sph-col {
    width: 100%;
    max-width: 33.3%;
    background: #F2F4F6;
    border: 1px solid #fff;
    margin: -1px; }

.page_w_host-doc {
  background: #F2F4F6;
  overflow: hidden; }
  .page_w_host-doc-left {
    padding: 0 15px;
    width: 100%;
    max-width: 50%; }
  .page_w_host-doc-right {
    padding: 0 15px;
    width: 100%;
    max-width: 50%; }
  .page_w_host-doc-bl {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 43px 0;
    position: relative; }
    .page_w_host-doc-bl:before {
      content: '';
      height: 100%;
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
      background: #065888; }
  .page_w_host-doc-txt {
    padding: 43px 0;
    max-width: 620px;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%; }
  .page_w_host-doc-btn {
    position: relative;
    z-index: 2; }

/*page_w_host*/
/*page_w_mob*/
.page_w_mob .demo-section {
  background: #FFFFFF; }

.page_w_mob-app {
  padding-top: 50px;
  background: #F2F4F6; }
  .page_w_mob-app-left {
    width: 100%;
    max-width: 700px;
    padding: 0 15px;
    margin-bottom: 50px; }
  .page_w_mob-app-right {
    max-width: 670px;
    width: 100%;
    padding: 0 15px; }
  .page_w_mob-app-bl {
    padding: 41px 50px 50px;
    background: #fff;
    border-top: 2px solid #f2f4f6; }
    .page_w_mob-app-bl:first-child {
      border-top: 0; }
    .page_w_mob-app-bl-top {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #B0B1B3; }
    .page_w_mob-app-bl-ttl {
      font-weight: 500;
      font-size: 36px;
      line-height: 110%;
      color: #065888;
      margin-bottom: 38px; }
  .page_w_mob-app-btns {
    display: flex;
    flex-wrap: wrap; }
    .page_w_mob-app-btns a {
      margin-right: 25px;
      font-size: 0; }
      .page_w_mob-app-btns a:last-child {
        margin-right: 0; }
  .page_w_mob-app-img {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: center; }
    .page_w_mob-app-img .sm {
      display: none; }

.page_w_mob-why {
  padding-top: 42px;
  background: #FFFFFF; }
  .page_w_mob-why .page_w_sys-why-top {
    justify-content: space-between; }
  .page_w_mob-why .page_w_sys-why-ttl {
    max-width: 893px; }

/*page_w_mob*/
/*page_decis*/
.page_decis-wrap {
  padding: 39px 0 50px;
  background: #F2F4F6; }

.page_decis .row {
  margin: 1px; }

.page_decis-bl {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 333px;
  padding: 41px 48px; }
  .page_decis-bl-img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    top: 0;
    left: 0;
    background: #065888;
    -webkit-background-size: cover;
    background-size: cover;
    transition: 0.3s; }
  .page_decis-bl-ttl {
    font-weight: 500;
    color: #065888;
    position: relative;
    flex: 1;
    z-index: 2;
    transition: 0.3s; }
    .page_decis-bl-ttl .ttl {
      font-size: 24px;
      line-height: 120%;
      display: block;
      margin-bottom: 5px; }
    .page_decis-bl-ttl .txt {
      font-size: 20px;
      line-height: 140%;
      display: block; }
  .page_decis-bl-ico {
    background: #FFFFFF;
    border-radius: 10px;
    width: 60px;
    height: 60px;
    padding: 10px;
    position: relative;
    z-index: 2; }
  .page_decis-bl:hover .page_decis-bl-img {
    opacity: 1;
    transition: 0.3s; }
  .page_decis-bl:hover .page_decis-bl-ttl {
    color: #FFFFFF;
    transition: 0.3s; }

.page_decis-col {
  width: 100%;
  max-width: 50%;
  background: #FFFFFF;
  border: 1px solid #F2F4F6;
  margin: -1px; }

/*page_decis*/
/*page_decis_sing*/
.page_decis_sing {
  background: #f2f4f6; }
  .page_decis_sing-right {
    width: 100%;
    max-width: 365px;
    padding: 0 15px; }
    .page_decis_sing-right_wrap {
      padding-left: 50px;
      padding-top: 50px; }
  .page_decis_sing-left {
    width: 100%;
    max-width: 1005px;
    padding: 0 15px; }
    .page_decis_sing-left .container {
      padding: 0; }
  .page_decis_sing-why {
    padding-top: 42px;
    background: #fff;
    position: relative; }
    .page_decis_sing-why:before {
      content: '';
      position: absolute;
      display: block;
      z-index: 1;
      top: 0;
      right: 0;
      width: calc(100vw + 100%);
      background: #FFFFFF;
      height: 100%; }
    .page_decis_sing-why .container {
      position: relative;
      z-index: 2; }
    .page_decis_sing-why .page_w_sys-why-top {
      padding-right: 30px; }
    .page_decis_sing-why .page_w_sys-why-col {
      max-width: 490px; }
  .page_decis_sing-func {
    padding: 42px 0 50px;
    background: #D8E9F3;
    position: relative; }
    .page_decis_sing-func:before {
      content: '';
      position: absolute;
      display: block;
      z-index: 1;
      top: 0;
      right: 0;
      width: calc(100vw + 100%);
      background: #D8E9F3;
      height: 100%; }
    .page_decis_sing-func .container {
      position: relative;
      z-index: 2;
      overflow: hidden; }
    .page_decis_sing-func-top {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 23px;
      align-items: center; }
    .page_decis_sing-func-ttl {
      flex: 1;
      margin-bottom: 20px; }
    .page_decis_sing-func-more {
      margin-bottom: 20px;
      padding-right: 30px; }
      .page_decis_sing-func-more a {
        font-size: 16px;
        line-height: 155%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #F90936; }
        .page_decis_sing-func-more a:hover {
          color: #065888; }
          .page_decis_sing-func-more a:hover svg path {
            fill: #065888; }
    .page_decis_sing-func-slider {
      max-width: 445px;
      width: 100%;
      position: static; }
      .page_decis_sing-func-slider .slick-list.draggable {
        overflow: visible; }
      .page_decis_sing-func-slider .slick-arrow {
        background: #FFFFFF;
        border-radius: 50%;
        padding: 10px 10px;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        margin-top: -20px; }
        .page_decis_sing-func-slider .slick-arrow.arrow-prev {
          left: 20px;
          z-index: 3; }
        .page_decis_sing-func-slider .slick-arrow.arrow-next {
          left: auto;
          right: 20px;
          z-index: 3; }
    .page_decis_sing-func-bl {
      border-right: 1px solid #d8e9f3;
      background: #FFFFFF; }
      .page_decis_sing-func-bl-img {
        background: #F2F4F6;
        padding: 59px 50px; }
        .page_decis_sing-func-bl-img img {
          filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.15)); }
      .page_decis_sing-func-bl-wrap {
        padding: 44px 50px;
        background: #FFFFFF; }
      .page_decis_sing-func-bl-ttl {
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        color: #065888;
        margin-bottom: 23px; }
      .page_decis_sing-func-bl-txt {
        font-weight: 500;
        font-size: 16px;
        line-height: 155%; }
  .page_decis_sing .page_w_host-doc {
    background: #FFFFFF;
    position: relative;
    overflow: visible; }
    .page_decis_sing .page_w_host-doc:before {
      content: '';
      position: absolute;
      display: block;
      z-index: 1;
      top: 0;
      right: 0;
      width: calc(100vw + 100%);
      background: #FFFFFF;
      height: 100%; }
    .page_decis_sing .page_w_host-doc .container {
      position: relative;
      z-index: 2; }
    .page_decis_sing .page_w_host-doc-bl:before {
      width: 100%; }
  .page_decis_sing-equip {
    padding-top: 43px; }
    .page_decis_sing-equip-ttl {
      margin-bottom: 43px; }
    .page_decis_sing-equip-col {
      margin-bottom: 73px;
      width: 100%;
      max-width: 50%;
      padding: 0 15px; }
    .page_decis_sing-equip-bl {
      max-width: 452px; }
      .page_decis_sing-equip-bl-img {
        margin-bottom: 39px; }
      .page_decis_sing-equip-bl-ttl {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #323232;
        margin-bottom: 15px; }
      .page_decis_sing-equip-bl-txt {
        font-weight: 500;
        font-size: 16px;
        line-height: 155%; }
  .page_decis_sing-goto {
    position: relative; }
    .page_decis_sing-goto .container {
      position: relative;
      z-index: 2; }
    .page_decis_sing-goto:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100vw;
      right: 0;
      top: 0;
      z-index: 1;
      background: #065888; }
  .page_decis_sing-app {
    position: relative;
    padding-bottom: 50px;
    background: #d8e9f3; }
    .page_decis_sing-app .container {
      position: relative;
      z-index: 2;
      padding: 0;
      max-width: 955px;
      margin: 0;
      background: #fff;
      padding: 41px 30px 40px; }
    .page_decis_sing-app:before {
      position: absolute;
      z-index: 1;
      content: '';
      width: 100vw;
      right: 0;
      top: 0;
      height: 100%;
      display: block;
      background: #d8e9f3; }
    .page_decis_sing-app-top {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #B0B1B3; }
    .page_decis_sing-app-ttl {
      font-weight: 500;
      font-size: 36px;
      line-height: 110%;
      text-align: center;
      color: #065888;
      margin-bottom: 38px; }
    .page_decis_sing-app-btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .page_decis_sing-app-btns a {
        margin: 0 13px 10px; }

/*page_decis_sing*/
/*page_equip*/
.page_equip-wrap {
  background: #F2F4F6;
  padding: 50px 0 43px; }

.page_equip-row {
  background: #FFFFFF;
  display: flex;
  flex-wrap: wrap; }

.page_equip-col {
  padding: 44px 50px;
  border-right: 1px solid #f2f4f6;
  border-bottom: 1px solid #f2f4f6;
  width: 100%;
  max-width: 25%; }
  .page_equip-col-ttl {
    display: block;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 16px; }
    .page_equip-col-ttl span {
      color: #065888; }

.page_equip-list li {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  margin-bottom: 15px; }
  .page_equip-list li a {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    text-decoration: underline; }
    .page_equip-list li a span {
      color: #323232; }

.page_equip-txt {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%; }

/*page_equip*/
/*page_equip_cat*/
.page_equip_cat {
  background: #f2f4f6; }
  .page_equip_cat-wrap {
    overflow: hidden; }
  .page_equip_cat-right {
    width: 100%;
    max-width: 365px;
    padding: 0 15px; }
    .page_equip_cat-right_wrap {
      padding-left: 50px;
      padding-top: 50px; }
  .page_equip_cat-left {
    width: 100%;
    max-width: 1005px;
    padding: 0 15px; }
    .page_equip_cat-left .container {
      padding: 0; }
  .page_equip_cat-list {
    position: relative; }
    .page_equip_cat-list-row {
      position: relative;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      padding: 1px 0; }
    .page_equip_cat-list:before {
      content: '';
      position: absolute;
      top: 0;
      right: 6px;
      width: 100vw;
      height: 100%;
      background: #fff;
      display: block; }
    .page_equip_cat-list-bl {
      border: 2px solid #f2f4f6;
      width: 100%;
      max-width: 33.3%;
      padding: 50px;
      margin: -1px;
      display: flex;
      flex-direction: column; }
      .page_equip_cat-list-bl-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 33px; }
      .page_equip_cat-list-bl-ttl {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #065888;
        display: block; }
        .page_equip_cat-list-bl-ttl:visited {
          color: #065888; }
      .page_equip_cat-list-bl-txt {
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        color: #323232;
        margin-top: 15px;
        flex: 1; }
      .page_equip_cat-list-bl-more, .page_equip_cat-list-bl-buy {
        margin-top: 12px; }
        .page_equip_cat-list-bl-more a.btn-gray_bord, .page_equip_cat-list-bl-buy a.btn-gray_bord {
          display: flex;
          flex-wrap: wrap;
          align-items: center; }
          .page_equip_cat-list-bl-more a.btn-gray_bord span, .page_equip_cat-list-bl-buy a.btn-gray_bord span {
            flex: 1; }
      .page_equip_cat-list-bl-more {
        margin-top: 33px; }
        .page_equip_cat-list-bl-more a.btn-gray_bord {
          color: #065888; }
  .page_equip_cat-right .sidebar {
    padding: 43px 0; }
    .page_equip_cat-right .sidebar-menu > ul > li > a {
      padding-right: 22px;
      position: relative; }
      .page_equip_cat-right .sidebar-menu > ul > li > a:before {
        content: '';
        display: block;
        position: absolute;
        border-bottom: 2px solid #323232;
        border-right: 2px solid #323232;
        width: 7px;
        height: 7px;
        right: 5px;
        top: 6px;
        transform: rotate(45deg); }
    .page_equip_cat-right .sidebar-menu > ul > li.current > a:before {
      transform: rotate(-135deg);
      top: 10px; }
  .page_equip_cat-right .sublist {
    padding-top: 15px; }

/*page_equip_cat*/
/*single_prod*/
.single_prod-wrap {
  overflow: hidden;
  background: #EAECED; }

.single_prod-right {
  width: 100%;
  max-width: 365px;
  padding: 0 15px; }
  .single_prod-right_wrap {
    padding-left: 50px;
    padding-top: 50px; }

.single_prod-left {
  width: 100%;
  max-width: 1005px;
  padding: 0 15px;
  position: relative; }
  .single_prod-left .container {
    padding: 0; }
  .single_prod-left-in {
    position: relative;
    z-index: 2; }
  .single_prod-left:before {
    content: '';
    position: absolute;
    top: 0;
    right: 15px;
    width: 100vw;
    height: 100%;
    background: #fff;
    display: block;
    z-index: 1; }

.single_prod-ttl {
  padding: 42px 0;
  font-weight: 500;
  font-size: 36px;
  line-height: 110%; }

.single_prod-main-txt {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  padding: 43px 0;
  padding-right: 35px;
  position: relative;
  min-height: 100%; }
  .single_prod-main-txt:before {
    content: '';
    position: absolute;
    top: 0;
    right: 15px;
    width: 100vw;
    height: 100%;
    background: #F2F4F6;
    display: block;
    z-index: 1; }
  .single_prod-main-txt > * {
    position: relative;
    z-index: 2; }
  .single_prod-main-txt.content-box ul {
    font-size: 16px; }
    .single_prod-main-txt.content-box ul li {
      margin-bottom: 10px; }
      .single_prod-main-txt.content-box ul li:before {
        background: #323232; }

.single_prod-main-left {
  padding: 0 15px;
  width: 100%;
  max-width: 50%; }

.single_prod-main-right {
  padding: 0 15px;
  width: 100%;
  max-width: 50%; }

.single_prod-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px 0; }
  .single_prod-img-slider {
    width: 331px;
    height: 223px;
    padding: 0 40px; }
    .single_prod-img-slider img {
      max-height: 223px; }
    .single_prod-img-slider .slick-arrow {
      position: absolute;
      top: 50%;
      margin-top: -11px; }
      .single_prod-img-slider .slick-arrow.arrow-prev {
        left: 0; }
      .single_prod-img-slider .slick-arrow.arrow-next {
        right: 0; }

.single_prod-btns {
  background: #D8E9F3; }
  .single_prod-btns-left {
    padding: 50px 15px;
    position: relative;
    width: 100%;
    max-width: 50%;
    text-align: center; }
    .single_prod-btns-left .btn {
      position: relative;
      z-index: 2; }
    .single_prod-btns-left:before {
      content: '';
      position: absolute;
      top: 0;
      right: 30px;
      width: 100vw;
      height: 100%;
      display: block;
      z-index: 1;
      background: #065888; }
  .single_prod-btns-right {
    padding: 50px 15px;
    width: 100%;
    max-width: 50%;
    text-align: center; }
    .single_prod-btns-right .btn {
      position: relative;
      z-index: 2; }

.single_prod-txt {
  padding: 43px 35px;
  padding-left: 0;
  position: relative; }
  .single_prod-txt:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    right: 0;
    height: 100%;
    display: block;
    z-index: 1;
    background: #F2F4F6; }
  .single_prod-txt > * {
    position: relative;
    z-index: 1; }

.single_prod-adv {
  padding: 50px 0 28px; }
  .single_prod-adv-bl {
    width: 25%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px; }
    .single_prod-adv-bl-img {
      margin-bottom: 19px; }
    .single_prod-adv-bl-txt {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      text-align: center; }

.single_prod-tech {
  position: relative;
  padding: 42px 0; }
  .single_prod-tech:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    right: 0;
    height: 100%;
    display: block;
    z-index: 1;
    background: #F2F4F6; }
  .single_prod-tech > * {
    position: relative;
    z-index: 2; }
  .single_prod-tech-ttl {
    margin-bottom: 43px; }
  .single_prod-tech-tbl {
    padding-right: 50px; }
    .single_prod-tech-tbl .woocommerce-product-attributes.shop_attributes {
      border: 0; }
    .single_prod-tech-tbl .shop_attributes th, .single_prod-tech-tbl .shop_attributes td {
      width: 50% !important;
      padding: 17px 24px !important;
      font-weight: 400 !important;
      font-style: normal !important;
      background: #fff !important;
      border: 0 !important;
      border-bottom: 2px solid #f2f4f6 !important; }
    .single_prod-tech-tbl .shop_attributes th:first-child, .single_prod-tech-tbl .shop_attributes td:first-child {
      text-align: left; }
    .single_prod-tech-tbl .shop_attributes th:last-child, .single_prod-tech-tbl .shop_attributes td:last-child {
      text-align: right;
      color: #065888; }

.single_prod-list-ttl {
  margin-bottom: 43px;
  margin-top: 30px;
  position: relative;
  z-index: 3; }

/*single_prod*/
/*page_news*/
.page_news-wrap {
  padding: 50px 0 0px; }

.page_news-top {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding-bottom: 30px; }
  .page_news-top-col {
    padding: 0 10px;
    margin-bottom: 20px; }
  .page_news-top-cat {
    background: #E2E4E6;
    border-radius: 20px;
    padding: 17px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    color: #323232;
    display: block; }
    .page_news-top-cat.current {
      background: #065888;
      color: #fff; }

.page_news-list-row {
  margin: 0 -15px; }

.page_news-list-bl {
  width: 100%;
  max-width: 405px;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .page_news-list-bl-img {
    display: block;
    text-align: center;
    margin-bottom: 31px; }
  .page_news-list-bl-cat {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #065888;
    margin-bottom: 20px; }
  .page_news-list-bl-ttl {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #323232;
    margin-bottom: 26px;
    display: block; }
  .page_news-list-bl-txt {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    color: #323232;
    margin-bottom: 20px;
    flex: 1; }
  .page_news-list-bl-date {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    color: #F90936; }

.page_news-list-col {
  padding: 0 15px;
  max-width: 456px;
  width: 100%;
  padding-bottom: 63px; }

.page_news-pagi {
  background: #F2F4F6;
  padding: 54px 0 34px;
  width: 100%; }
  .page_news-pagi .wp-pagenavi {
    display: flex;
    flex-wrap: wrap; }
    .page_news-pagi .wp-pagenavi span, .page_news-pagi .wp-pagenavi a {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #B0B1B3;
      border: 0;
      padding: 0;
      margin: 0 25px 20px; }
    .page_news-pagi .wp-pagenavi > *:first-child {
      margin-left: 0; }
    .page_news-pagi .wp-pagenavi > *:last-child {
      margin-right: 0; }
    .page_news-pagi .wp-pagenavi span {
      color: #065888; }

/*page_news*/
/*single_news*/
.single_news {
  background: #f2f4f6; }
  .single_news-wrap {
    overflow: hidden; }
  .single_news-right {
    width: 100%;
    max-width: 365px;
    padding: 0 15px; }
    .single_news-right_wrap {
      padding-left: 50px;
      padding-top: 50px; }
    .single_news-right .sidebar {
      padding: 43px 0; }
    .single_news-right-back {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #065888;
      display: inline-block;
      margin-bottom: 43px; }
      .single_news-right-back svg {
        margin-right: 15px; }
  .single_news-left {
    width: 100%;
    max-width: 1005px;
    padding: 0 15px; }
    .single_news-left .container {
      padding: 0; }
  .single_news-left_wrap {
    position: relative;
    padding: 43px 0px 41px;
    margin-bottom: 2px;
    padding-right: 50px; }
    .single_news-left_wrap-row {
      position: relative;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      padding: 1px 0; }
    .single_news-left_wrap:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100%;
      background: #fff;
      display: block; }
  .single_news-date {
    position: relative;
    z-index: 2;
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    margin-bottom: 10px;
    color: #F90936; }
  .single_news-ttl {
    position: relative;
    z-index: 2;
    font-weight: 500;
    margin-bottom: 20px; }
  .single_news-exerpt {
    z-index: 2;
    position: relative;
    margin-bottom: 43px;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #065888; }
  .single_news-img {
    margin-bottom: 39px;
    position: relative;
    z-index: 2; }
  .single_news-content {
    position: relative;
    z-index: 2; }

/*single_news*/
/*page_partn*/
.page_partn-advan {
  overflow: hidden; }
  .page_partn-advan-left {
    padding: 0 15px;
    width: 100%;
    max-width: 50%;
    position: relative; }
    .page_partn-advan-left:before {
      content: '';
      display: block;
      width: 720px;
      height: 527px;
      background: no-repeat 0% 0%;
      background-image: url("../img/partn-page.jpg");
      background-size: cover;
      left: -35px;
      position: absolute;
      top: 0;
      z-index: 1; }
    .page_partn-advan-left-bl {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      padding: 41px 0;
      padding-right: 15px;
      height: 527px; }
  .page_partn-advan-right {
    width: 100%;
    max-width: 50%;
    padding: 0 15px; }
  .page_partn-advan-content {
    margin-bottom: 43px; }
    .page_partn-advan-content.content-box > * {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%; }
    .page_partn-advan-content.content-box ul li {
      margin-bottom: 3px; }
      .page_partn-advan-content.content-box ul li:before {
        background: #B0B1B3; }
  .page_partn-advan-bl {
    padding-left: 35px;
    padding-bottom: 50px;
    padding-top: 50px; }
  .page_partn-advan-ttl {
    color: #fff;
    font-weight: 500;
    font-size: 36px;
    line-height: 110%;
    padding-bottom: 30px;
    flex: 1; }
  .page_partn-advan-txt {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #fff; }

.page_partn-steps {
  overflow: hidden;
  background: #F2F4F6; }
  .page_partn-steps-bl {
    padding: 41px 0;
    max-width: 620px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%; }
  .page_partn-steps-left {
    width: 100%;
    max-width: 50%;
    padding: 0 15px; }
  .page_partn-steps-ttl {
    font-weight: 500;
    font-size: 36px;
    line-height: 110%;
    color: #065888;
    margin-bottom: 39px; }
  .page_partn-steps-txt.content-box > * {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%; }
  .page_partn-steps-txt.content-box ol li {
    margin-bottom: 3px; }
    .page_partn-steps-txt.content-box ol li:before {
      background: #B0B1B3; }
  .page_partn-steps-right {
    padding: 0 15px;
    width: 100%;
    max-width: 50%;
    position: relative; }
    .page_partn-steps-right:before {
      content: '';
      display: block;
      width: 100vw;
      height: 100%;
      background: #065888;
      left: 0px;
      position: absolute;
      top: 0;
      z-index: 1; }
  .page_partn-steps-form_wrap {
    position: relative;
    z-index: 2;
    padding: 50px 0;
    padding-left: 35px; }
  .page_partn-steps-form-ttl {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 44px; }

/*page_partn*/
/*page_cont*/
.page_cont-data {
  overflow: hidden; }

.page_cont-map {
  width: 720px;
  height: 540px; }

.page_cont-left {
  padding: 0 15px;
  width: 100%;
  max-width: 50%; }

.page_cont-right {
  padding: 0 15px;
  width: 100%;
  max-width: 50%;
  position: relative;
  height: 540px; }

.page_cont-map {
  position: absolute;
  left: 0;
  background: #f2f4f6; }

.page_cont-bl {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  padding-right: 35px;
  justify-content: center;
  height: 100%; }
  .page_cont-bl-ttl {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 18px; }
  .page_cont-bl-phn {
    margin-bottom: 10px; }
    .page_cont-bl-phn a {
      font-weight: 500;
      font-size: 36px;
      line-height: 110%;
      color: #F90936; }
      .page_cont-bl-phn a:hover {
        color: #065888; }
  .page_cont-bl-ml {
    margin-bottom: 25px; }
    .page_cont-bl-ml a {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: #065888; }
      .page_cont-bl-ml a:hover {
        color: #F90936; }
  .page_cont-bl-adr.content-box p {
    margin-bottom: 0; }

.page_cont-container:nth-child(2n) .row {
  flex-direction: row-reverse; }

.page_cont-container:nth-child(2n) .page_cont-map {
  left: auto;
  right: 0; }

.page_cont-container:nth-child(2n) .page_cont-bl {
  padding-right: 0;
  padding-left: 35px; }

.page_cont-form {
  background: #065888;
  padding: 50px 0; }
  .page_cont-form-ttl {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 44px; }
  .page_cont-form .form-cont-col {
    padding: 0 15px;
    width: 100%;
    max-width: 50%; }

/*page_cont*/
/*page_tech*/
.page_tech-info {
  background: #EAECED; }
  .page_tech-info-left {
    width: 100%;
    max-width: 50%;
    padding: 50px 15px;
    position: relative; }
    .page_tech-info-left:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 720px;
      top: 0;
      right: 0;
      background: #065888; }
  .page_tech-info-right {
    width: 100%;
    max-width: 50%;
    padding: 50px 15px; }
  .page_tech-info-form {
    position: relative;
    z-index: 2; }
    .page_tech-info-form .form.form-partn {
      max-width: 620px; }
  .page_tech-info-bl {
    display: flex;
    max-width: 620px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-left: 35px; }
  .page_tech-info-txt {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 18px; }
  .page_tech-info-phn {
    margin-bottom: 12px; }
    .page_tech-info-phn a {
      font-weight: 500;
      font-size: 36px;
      line-height: 110%;
      color: #F90936; }
  .page_tech-info-ml a {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    text-decoration-line: underline;
    color: #065888; }

/*page_tech*/
.footer {
  padding: 49px 0;
  border-top: 2px solid #f2f4f6; }

.f-logo {
  padding: 0 15px;
  width: 49%; }

.f-cont {
  padding: 0 15px;
  width: 100%;
  max-width: 49%; }

.f-cont-top {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #B0B1B3;
  margin-bottom: 26px; }

.f-cont-phn {
  margin-bottom: 14px; }
  .f-cont-phn a {
    font-weight: 500;
    font-size: 36px;
    line-height: 110%;
    color: #F90936; }

.f-cont-ml {
  margin-bottom: 36px; }
  .f-cont-ml a {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    text-decoration-line: underline;
    color: #065888; }

.f-copy {
  max-width: 49%;
  padding: 0 15px;
  width: 100%; }

.f-btn {
  max-width: 49%;
  padding: 0 15px;
  width: 100%; }

.sidebar_prod-wrap {
  padding-bottom: 30px; }

/*header*/
@media (min-width: 1200px) {
  .h-mob {
    display: none !important; } }

@media (max-width: 1199px) {
  .h-mob {
    top: 120px;
    position: absolute;
    width: 100%;
    background: #fff;
    min-height: calc(100vh - 120px);
    padding: 40px 0; }
    .h-mob .h-soc svg path {
      fill: #b0b1b2; }
    .h-mob .h-menu ul {
      justify-content: flex-start; }
      .h-mob .h-menu ul li a {
        color: #000; }
    .h-mob .h-auth a {
      background: url("../img/auth_white.svg") no-repeat 0 50%;
      color: #000; }
    .h-mob .h-phn a {
      color: #000; }
  .h-menu ul li.menu-item-has-children:before {
    border-color: #065888; } }

@media (max-width: 1479px) and (min-width: 1200px) {
  .h-menu ul {
    margin: 0 -11px; }
    .h-menu ul li {
      padding: 0 5px 15px; }
      .h-menu ul li a {
        font-size: 14px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .h-menu {
    display: none; }
  .h-info {
    flex-direction: column; }
  .h-auth {
    padding-top: 18px; }
  .h-btn {
    display: block; }
  .h-auth a {
    font-size: 16px; }
  .h-phn {
    margin-top: 0; }
    .h-phn a {
      font-size: 16px; }
  .h-mob .h-menu {
    display: block; }
  .h-mob .h-soc {
    display: none; }
  .h-mob .h-auth {
    display: none; }
  .h-mob .h-phn {
    display: none; } }

@media (max-width: 959px) and (min-width: 640px) {
  .h-menu {
    display: none; }
  .h-info {
    display: none; }
  .h-auth {
    padding-top: 18px; }
  .h-btn {
    display: block; }
  .h-auth a {
    font-size: 16px; }
  .h-phn {
    margin-top: 0; }
    .h-phn a {
      font-size: 16px; }
  .h-mob {
    top: 106px;
    min-height: calc(100vh - 106px); }
    .h-mob .h-menu {
      display: block; }
    .h-mob .h-soc {
      display: block; }
    .h-mob .h-auth {
      display: none; }
    .h-mob .h-phn {
      display: block; } }

@media (max-width: 639px) {
  .h-logo {
    margin-top: 0;
    padding: 30px 15px; }
    .h-logo svg path[fill="white"] {
      fill: #000000; }
  .h-menu {
    display: none; }
  .h-cont {
    padding: 30px 0;
    justify-content: flex-end; }
  .h-info {
    display: none; }
  .h-auth {
    display: none; }
  .h-btn {
    display: block;
    padding: 0 15px; }
  .h-auth a {
    font-size: 16px; }
  .h-phn {
    margin-top: 0; }
    .h-phn a {
      font-size: 16px; }
  .header {
    background: #FFFFFF; }
    .header.static {
      background: #FFFFFF; }
  .h-mob {
    top: 125px;
    min-height: calc(100vh - 125px); }
    .h-mob .h-menu {
      display: block; }
      .h-mob .h-menu ul {
        flex-direction: column;
        align-items: flex-start; }
      .h-mob .h-menu .sub-menu {
        position: static;
        display: none; }
      .h-mob .h-menu li.menu-item-has-children.hovered .sub-menu {
        display: block; }
    .h-mob .h-soc {
      display: block; }
    .h-mob .h-auth {
      display: block; }
    .h-mob .h-phn {
      display: block; } }

/*header*/
@media (max-width: 639px) {
  .video-back {
    display: none; } }

/*main page*/
@media (min-width: 1200px) {
  .main-first {
    min-height: 900px; } }

@media (max-width: 1479px) and (min-width: 1200px) {
  .main-numbers-left {
    max-width: 720px; }
  .main-numbers-bl-ttl {
    font-size: 36px; }
  .main-numbers-bl-txt {
    font-size: 14px; }
  .main-numbers-col {
    max-width: 235px; } }

@media (min-width: 1480px) {
  .main-hot .container {
    max-width: 1470px; }
  .main-hot-slide-left {
    padding-left: 50px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .main-first {
    min-height: 652px; }
    .main-first-ttl {
      font-size: 36px;
      line-height: 110%; }
  .main-numbers-left {
    max-width: 603px; }
  .main-numbers-bl-ttl {
    font-size: 45px;
    line-height: 57px; }
  .main-numbers-bl-txt {
    font-size: 18px;
    line-height: 140%; }
  .main-numbers-col {
    max-width: 286px; }
  .main-numbers-one-txt {
    font-size: 17px; }
  .main-hot-slide-left {
    max-width: 380px; }
  .main-hot-slider .slick-arrow.arrow-prev {
    left: 310px; }
  .main-hot-slider .slick-arrow.arrow-next {
    left: 350px; }
  .main-revs-left {
    max-width: none; }
    .main-revs-left:before {
      left: -50%;
      width: calc(100% + 100%); }
  .main-revs-right {
    flex: unset;
    width: 100%;
    max-width: none; }
    .main-revs-right-top {
      position: relative;
      overflow: visible; }
      .main-revs-right-top:before {
        content: '';
        background: #FFFFFF;
        z-index: 1;
        position: absolute;
        left: -50%;
        top: 0;
        width: calc(100% + 100%);
        height: 100%;
        display: block; }
    .main-revs-right-btm {
      padding: 46px 0px; }
  .main-revs-slider {
    position: relative;
    z-index: 2; }
  .main-revs-slide {
    padding-left: 0; } }

@media (max-width: 959px) and (min-width: 640px) {
  .main-first {
    min-height: 667px; }
    .main-first-ttl {
      font-size: 36px;
      line-height: 110%; }
    .main-first-btn {
      margin-bottom: 193px; }
  .main-numbers-col {
    max-width: 50%; }
  .main-numbers-bl-ttl {
    font-size: 45px;
    line-height: 57px; }
  .main-numbers-one {
    height: 357px;
    padding: 35px 0 43px; }
    .main-numbers-one:before {
      width: 899px;
      left: -160px; }
  .main-hot-slider {
    margin-left: -15px;
    width: calc(100% + 30px); }
  .main-hot-slide-left {
    max-width: none; }
  .main-hot-slide-right {
    flex: unset; }
  .main-hot-slide-txt {
    margin-bottom: 160px; }
  .main-hot-slider .slick-arrow.arrow-next {
    left: auto;
    right: 15px; }
  .main-hot-slider .slick-arrow.arrow-prev {
    left: auto;
    right: 80px; }
  .main-revs-left {
    max-width: none; }
    .main-revs-left:before {
      left: -50%;
      width: calc(100% + 100%); }
  .main-revs-right {
    flex: unset;
    width: 100%;
    max-width: none; }
    .main-revs-right-top {
      position: relative;
      overflow: visible; }
      .main-revs-right-top:before {
        content: '';
        background: #FFFFFF;
        z-index: 1;
        position: absolute;
        left: -50%;
        top: 0;
        width: calc(100% + 100%);
        height: 100%;
        display: block; }
    .main-revs-right-btm {
      padding: 46px 0px; }
  .main-revs-slider {
    position: relative;
    z-index: 2; }
  .main-revs-slide {
    padding-left: 0; }
  .main-revs-slider_text.slick-arrow.arrow-prev {
    left: auto;
    right: 55px; }
  .main-revs-slider_text.slick-arrow.arrow-next {
    right: 0; }
  .main-news-col {
    width: 100%; }
    .main-news-col:first-child .main-news-bl {
      border-bottom: 2px solid #e2e4e6;
      border-right: 0; }
  .main-news-ttl {
    width: 100%;
    flex: unset;
    margin-bottom: 16px; }
  .main-news-more {
    margin-bottom: 38px; } }

@media (max-width: 639px) {
  .main-first {
    background: #065888 !important; }
    .main-first:before {
      display: none; }
    .main-first-ttl {
      font-size: 24px;
      line-height: 120%; }
  .main-numbers-bl-ttl {
    font-size: 45px;
    line-height: 57px; }
  .main-numbers-bl-txt {
    font-size: 20px;
    line-height: 140%; }
  .main-numbers-one {
    height: 357px;
    padding: 35px 0 43px; }
    .main-numbers-one:before {
      width: 639px;
      left: -30px; }
  .main-hot-slider {
    margin-left: -30px;
    width: calc(100% + 60px); }
  .main-hot-slide-left {
    max-width: none;
    padding: 46px 30px; }
  .main-hot-slide-right {
    flex: unset; }
  .main-hot-slide-top {
    margin-top: 54px; }
  .main-hot-slide-txt {
    margin-bottom: 160px; }
  .main-hot-slider .slick-arrow.arrow-next {
    left: 85px; }
  .main-hot-slider .slick-arrow.arrow-prev {
    left: 30px; }
  .main-revs-left {
    max-width: none; }
    .main-revs-left:before {
      left: -50%;
      width: calc(100% + 100%); }
  .main-revs-right {
    flex: unset;
    width: 100%;
    max-width: none;
    padding: 0 15px; }
    .main-revs-right-top {
      position: relative;
      overflow: visible; }
      .main-revs-right-top:before {
        content: '';
        background: #FFFFFF;
        z-index: 1;
        position: absolute;
        left: -50%;
        top: 0;
        width: calc(100% + 100%);
        height: 100%;
        display: block; }
    .main-revs-right-btm {
      padding: 46px 0px; }
  .main-revs-slider {
    position: relative;
    z-index: 2; }
  .main-revs-slide {
    padding-left: 0; }
  .main-revs-slider_text .slick-arrow.arrow-prev {
    left: 0;
    right: auto; }
  .main-revs-slider_text .slick-arrow.arrow-next {
    left: 55px;
    right: auto; }
  .main-revs-slide_text-top {
    margin-top: 54px; }
  .main-revs-slide_text-txt {
    font-size: 20px;
    line-height: 140%; }
  .main-news-col {
    width: 100%; }
    .main-news-col:first-child .main-news-bl {
      border-bottom: 2px solid #e2e4e6;
      border-right: 0; }
  .main-news-bl {
    padding: 25px; }
  .main-news-ttl {
    width: 100%;
    flex: unset;
    margin-bottom: 16px; }
  .main-news-more {
    margin-bottom: 38px; } }

/*main page*/
/*page about*/
@media (min-width: 1200px) {
  .page_about-first {
    min-height: 900px; } }

@media (max-width: 1479px) and (min-width: 1200px) {
  .page_about-hist-left, .page_about-hist-right {
    max-width: 585px; }
  .page_about-info-right {
    max-width: 500px; }
  .page_about-empl-bl {
    max-width: 288px; }
  .page_about-sert-left {
    max-width: 805px; }
  .page_about-partn-right {
    max-width: 670px; }
  .page_about-partn-left {
    max-width: 500px; }
  .page_about-team-left {
    max-width: 500px; }
  .page_about-team-img:before {
    left: auto;
    right: -30px; }
  .page_about-team-right {
    max-width: 670px; }
  .page_about-team-bl {
    padding-left: 40px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .page_about-first {
    min-height: 652px; }
    .page_about-first-ttl {
      font-size: 36px;
      line-height: 110%; }
  .page_about-hist-cont-ttl {
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 34px; }
  .page_about-hist-right {
    max-width: none; }
  .page_about-dates-slider_wrap {
    overflow: hidden; }
  .page_about-info-left {
    order: 2;
    max-width: none; }
  .page_about-info-right {
    order: 1;
    max-width: none; }
  .page_about-info-bl {
    padding: 42px 0px; }
    .page_about-info-bl:before {
      left: -50px;
      width: 960px; }
  .page_about-info-directors {
    max-width: none;
    width: 100%; }
    .page_about-info-directors-bl {
      width: 50%; }
  .page_about-empl-bl {
    max-width: 50%; }
  .page_about-sert-right {
    order: 1;
    max-width: none; }
    .page_about-sert-right-bl {
      display: flex;
      justify-content: space-between;
      flex-direction: row; }
      .page_about-sert-right-bl:before {
        width: 100%;
        top: 0;
        display: block;
        left: -90px;
        height: 100%;
        width: calc(100% + 160px);
        background: #065888; }
  .page_about-sert-left {
    order: 2; }
  .page_about-partn-left {
    max-width: none; }
  .page_about-partn-right {
    max-width: none; }
  .page_about-partn-btn {
    padding: 70px 0; }
    .page_about-partn-btn:before {
      background: #d8e9f3;
      z-index: 1;
      left: -50%;
      width: calc(100% + 100%);
      height: 100%; }
  .page_about-team-left {
    max-width: 50%; }
  .page_about-team-img:before {
    left: auto;
    right: 0; }
  .page_about-team-right {
    max-width: 50%; } }

@media (max-width: 959px) and (min-width: 640px) {
  .page_about-first {
    min-height: 667px; }
    .page_about-first-ttl {
      font-size: 36px;
      line-height: 110%; }
    .page_about-first-btn {
      margin-bottom: 193px; }
  .page_about-hist-cont-ttl {
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 34px; }
  .page_about-hist-right {
    max-width: none; }
  .page_about-dates-slider_wrap {
    overflow: hidden; }
  .page_about-info-left {
    order: 2;
    max-width: none; }
  .page_about-info-right {
    order: 1;
    max-width: none; }
  .page_about-info-bl {
    padding: 42px 0px; }
    .page_about-info-bl:before {
      left: -50px;
      width: 960px; }
  .page_about-info-directors {
    max-width: none;
    width: 100%; }
    .page_about-info-directors-bl {
      width: 50%; }
  .page_about-empl-bl {
    max-width: 50%; }
  .page_about-sert-bl-img img {
    margin: 0 auto; }
  .page_about-sert-right {
    order: 1;
    max-width: none; }
    .page_about-sert-right-bl {
      display: flex;
      justify-content: space-between;
      flex-direction: row; }
      .page_about-sert-right-bl:before {
        width: 100%;
        top: 0;
        display: block;
        left: -90px;
        height: 100%;
        width: calc(100% + 160px);
        background: #065888; }
  .page_about-sert-left {
    order: 2; }
  .page_about-partn-left {
    max-width: none; }
  .page_about-partn-right {
    max-width: none; }
  .page_about-partn-btn {
    padding: 70px 0; }
    .page_about-partn-btn:before {
      background: #d8e9f3;
      z-index: 1;
      left: -50%;
      width: calc(100% + 100%);
      height: 100%; }
  .page_about-team-img:before {
    position: static;
    width: 640px;
    height: 427px;
    margin-left: -30px; }
  .page_about-team-txt {
    flex: unset;
    margin-bottom: 44px; }
  .page_about-team-bl {
    padding-left: 0;
    height: auto; } }

@media (max-width: 639px) {
  .page_about-first {
    background: #065888 !important; }
    .page_about-first:before {
      display: none; }
    .page_about-first-ttl {
      font-size: 24px;
      line-height: 120%; }
  .page_about-hist-cont-ttl {
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 34px; }
  .page_about-hist-right {
    max-width: none; }
  .page_about-dates-slider_wrap {
    overflow: hidden; }
  .page_about-info-left {
    order: 2;
    max-width: none; }
  .page_about-info-right {
    order: 1;
    max-width: none; }
  .page_about-info-bl {
    padding: 42px 0px;
    min-height: 1px; }
    .page_about-info-bl:before {
      left: -50px;
      width: 960px; }
    .page_about-info-bl-ttl {
      font-size: 24px;
      line-height: 120%; }
    .page_about-info-bl-txt {
      font-size: 16px;
      line-height: 155%; }
  .page_about-info-directors-bl {
    max-width: 220px;
    width: 100%; }
  .page_about-empl .row {
    justify-content: space-between; }
  .page_about-empl-bl {
    max-width: 220px;
    width: 100%; }
  .page_about-sert-ttl {
    width: 100%;
    margin-bottom: 53px; }
  .page_about-sert-bl-img img {
    margin: 0 auto; }
  .page_about-sert-right {
    order: 1;
    max-width: none; }
    .page_about-sert-right-bl {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding-left: 0;
      flex-wrap: wrap; }
      .page_about-sert-right-bl:before {
        width: 100%;
        top: 0;
        display: block;
        left: -90px;
        height: 100%;
        width: calc(100% + 160px);
        background: #065888; }
  .page_about-sert-left {
    order: 2; }
  .page_about-partn-left {
    max-width: none; }
  .page_about-partn-right {
    max-width: none; }
  .page_about-partn-btn {
    padding: 70px 0; }
    .page_about-partn-btn:before {
      background: #d8e9f3;
      z-index: 1;
      left: -50%;
      width: calc(100% + 100%);
      height: 100%; }
  .page_about-team-img:before {
    position: static;
    width: 640px;
    height: 427px;
    margin-left: -30px; }
  .page_about-team-txt {
    flex: unset; }
  .page_about-team-bl {
    padding-left: 0;
    height: auto; } }

/*page about*/
/*page serv*/
@media (max-width: 959px) {
  .page_serv-row-left {
    max-width: none; }
  .page_serv-row-right {
    max-width: none;
    padding-left: 0;
    padding-top: 44px; }
  .page_serv-row-img {
    height: 427px;
    margin: 0;
    margin-left: -30px;
    width: calc(100% + 60px); } }

@media (max-width: 639px) {
  .page_serv-row-img {
    background: #fff !important;
    height: 189px; }
  .page_serv-row-num {
    color: #F90936; } }

/*page serv*/
/*page_w_sys*/
@media (max-width: 1479px) and (min-width: 1200px) {
  .page_w_sys-numbers-col {
    max-width: 290px; }
  .page_w_sys-numbers-bl-txt {
    font-size: 18px; }
  .page_w_sys-team-left {
    max-width: 500px; }
  .page_w_sys-team-img:before {
    left: auto;
    right: -30px; }
  .page_w_sys-team-right {
    max-width: 670px; }
  .page_w_sys-team-bl {
    padding-left: 40px; }
  .page_w_sys-sph-bl {
    height: 290px;
    padding: 41px 30px; }
  .page_w_sys-why-col {
    max-width: 390px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .page_w_sys-numbers-col {
    max-width: 50%; }
  .page_w_sys-team-left {
    max-width: none; }
  .page_w_sys-team-img {
    height: 640px; }
    .page_w_sys-team-img:before {
      left: -50px;
      width: calc(100% + 100px);
      background-repeat: no-repeat;
      height: 640px;
      background-position: 50% 50%;
      background-size: cover; }
  .page_w_sys-team-right {
    max-width: none; }
  .page_w_sys-team-txt {
    width: 100%;
    flex: unset; }
    .page_w_sys-team-txt ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .page_w_sys-team-txt ul li {
        width: 50%; }
  .page_w_sys-team-bl {
    min-height: 1px;
    padding-left: 0; }
  .page_w_sys-sph-col {
    max-width: 50%; }
    .page_w_sys-sph-col.big {
      max-width: 100%; }
  .page_w_sys-prod-col {
    max-width: none; }
    .page_w_sys-prod-col:first-child {
      border-right: 0;
      border-bottom: 1px solid #FFFFFF; }
    .page_w_sys-prod-col:last-child {
      padding-left: 15px; }
  .page_w_sys-prod-bl-txt {
    max-width: none;
    margin-bottom: 43px; }
  .page_w_sys-info-left {
    max-width: none; }
  .page_w_sys-info-ttl {
    max-width: none; }
  .page_w_sys-info-right {
    max-width: none; }
  .page_w_sys-cats-col {
    max-width: 50%; }
  .page_w_sys-why-col {
    max-width: 50%; } }

@media (max-width: 959px) and (min-width: 640px) {
  .page_w_sys-numbers-col {
    max-width: 50%; }
  .page_w_sys-team-left {
    max-width: none; }
  .page_w_sys-team-img {
    height: 640px; }
    .page_w_sys-team-img:before {
      left: -30px;
      width: calc(100% + 60px);
      background-repeat: no-repeat;
      height: 427px;
      background-position: 50% 50%;
      background-size: cover; }
  .page_w_sys-team-right {
    max-width: none; }
  .page_w_sys-team-txt {
    width: 100%;
    flex: unset; }
  .page_w_sys-team-bl {
    min-height: 1px;
    padding-left: 0; }
  .page_w_sys-sph-col {
    max-width: 100%; }
    .page_w_sys-sph-col.big {
      max-width: 100%; }
  .page_w_sys-prod-col {
    max-width: none; }
    .page_w_sys-prod-col:first-child {
      border-right: 0;
      border-bottom: 1px solid #FFFFFF; }
    .page_w_sys-prod-col:last-child {
      padding-left: 15px; }
  .page_w_sys-prod-bl-txt {
    max-width: none;
    margin-bottom: 43px; }
  .page_w_sys-info-left {
    max-width: none; }
  .page_w_sys-info-ttl {
    max-width: none; }
  .page_w_sys-info-right {
    max-width: none; }
  .page_w_sys-cats-col {
    max-width: 50%; }
  .page_w_sys-why-ttl {
    flex: unset;
    width: 100%; }
  .page_w_sys-why-col {
    max-width: 100%; } }

@media (max-width: 639px) {
  .page_w_sys-numbers-col {
    max-width: 100%; }
  .page_w_sys-team-left {
    max-width: none; }
  .page_w_sys-team-img {
    display: none; }
  .page_w_sys-team-right {
    max-width: none; }
  .page_w_sys-team-txt {
    width: 100%;
    flex: unset; }
  .page_w_sys-team-bl {
    min-height: 1px;
    padding-left: 0; }
  .page_w_sys-sph {
    padding-bottom: 0; }
    .page_w_sys-sph-col {
      border: 0;
      border-bottom: 1px solid #FFFFFF;
      margin: 0 -30px;
      width: calc(100% + 60px);
      max-width: none; }
      .page_w_sys-sph-col.big {
        max-width: none; }
    .page_w_sys-sph-bl {
      padding: 41px 30px; }
  .page_w_sys-prod-col {
    max-width: none; }
    .page_w_sys-prod-col:first-child {
      border-right: 0;
      border-bottom: 1px solid #FFFFFF; }
    .page_w_sys-prod-col:last-child {
      padding-left: 15px; }
  .page_w_sys-prod-bl-txt {
    max-width: none;
    margin-bottom: 43px; }
  .page_w_sys-info-left {
    max-width: none; }
  .page_w_sys-info-ttl {
    max-width: none; }
  .page_w_sys-info-right {
    max-width: none; }
  .page_w_sys-cats-col {
    max-width: 100%; }
  .page_w_sys-why-ttl {
    flex: unset;
    width: 100%; }
  .page_w_sys-why-col {
    max-width: 100%; } }

/*page_w_sys*/
/*page_w_host*/
@media (max-width: 1199px) and (min-width: 960px) {
  .page_w_host-sph-col {
    max-width: 50%; }
  .page_w_host-doc-left {
    max-width: none; }
  .page_w_host-doc-txt {
    max-width: none; }
  .page_w_host-doc-bl:before {
    left: -50%;
    width: calc(100vw + 100%); }
  .page_w_host-doc-right {
    max-width: none; } }

@media (max-width: 959px) and (min-width: 640px) {
  .page_w_host-adv-bl-txt ul li {
    width: 100%; }
  .page_w_host-sph-col {
    max-width: 100%; }
  .page_w_host-doc-left {
    max-width: none; }
  .page_w_host-doc-txt {
    max-width: none; }
  .page_w_host-doc-bl:before {
    left: -50%;
    width: calc(100vw + 100%); }
  .page_w_host-doc-right {
    max-width: none; } }

@media (max-width: 639px) {
  .page_w_host-adv-bl {
    padding: 46px 30px;
    margin: 0 -30px; }
    .page_w_host-adv-bl-txt ul li {
      width: 100%; }
  .page_w_host-sph {
    padding-bottom: 0; }
    .page_w_host-sph-col {
      border: 0;
      border-bottom: 1px solid #FFFFFF;
      margin: 0 -30px;
      width: calc(100% + 60px);
      max-width: none; }
      .page_w_host-sph-col.big {
        max-width: none; }
    .page_w_host-sph-bl {
      padding: 41px 30px; }
  .page_w_host-doc-left {
    max-width: none; }
  .page_w_host-doc-txt {
    max-width: none; }
  .page_w_host-doc-bl:before {
    left: -50%;
    width: calc(100vw + 100%); }
  .page_w_host-doc-right {
    max-width: none; } }

/*page_w_host*/
/*page_w_host*/
@media (max-width: 1479px) and (min-width: 1200px) {
  .page_w_mob-app-left {
    max-width: 600px; }
  .page_w_mob-app-right {
    max-width: 570px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .page_w_mob-app-img {
    height: auto;
    padding-bottom: 50px;
    align-items: flex-start; }
    .page_w_mob-app-img .sm {
      display: block; }
    .page_w_mob-app-img .lg {
      display: none; }
  .page_w_mob-app-left {
    max-width: 460px; }
  .page_w_mob-app-right {
    max-width: 430px; }
  .page_w_mob-app-bl {
    padding-right: 34px; }
  .page_w_mob-app-btns a:last-child {
    margin-right: 0; } }

@media (max-width: 959px) and (min-width: 640px) {
  .page_w_mob-app-left {
    max-width: 100%; }
  .page_w_mob-app-right {
    display: none; }
  .page_w_mob-app-bl {
    padding-right: 34px; } }

@media (max-width: 639px) {
  .page_w_mob-app-left {
    max-width: 100%; }
  .page_w_mob-app-right {
    display: none; }
  .page_w_mob-app-bl {
    padding: 41px 30px 50px;
    margin: 0 -30px; }
  .page_w_mob-app-btns a {
    margin-bottom: 25px;
    margin-right: 0 !important; } }

/*page_w_host*/
/*page_decis*/
@media (max-width: 959px) and (min-width: 640px) {
  .page_decis-col {
    max-width: 100%; } }

@media (max-width: 639px) {
  .page_decis-col {
    border: 0;
    border-bottom: 1px solid #F2F4F6;
    margin: 0 -30px;
    width: calc(100% + 60px);
    max-width: none; }
    .page_decis-col.big {
      max-width: none; }
  .page_decis-bl {
    padding: 41px 30px; } }

/*page_decis*/
/*page_decis_sing*/
@media (min-width: 1200px) {
  .page_decis_sing .demo-section-bl:before {
    width: 490px; } }

@media (max-width: 1479px) and (min-width: 1200px) {
  .page_decis_sing-left {
    max-width: 900px; }
  .page_decis_sing-right {
    max-width: 270px; }
    .page_decis_sing-right_wrap {
      padding-left: 0; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .page_decis_sing-left {
    order: 2; }
  .page_decis_sing-right {
    order: 1;
    max-width: none; }
    .page_decis_sing-right_wrap {
      padding-left: 0;
      padding-top: 42px; }
  .page_decis_sing-why:before {
    left: -50%; }
  .page_decis_sing-why .page_w_sys-why-col {
    max-width: 50%; }
  .page_decis_sing-wrap {
    overflow: hidden;
    max-width: 100%; }
  .page_decis_sing-func:before {
    left: -50%; }
  .page_decis_sing .page_w_host-doc-bl:before {
    width: calc(100vw + 100%); }
  .page_decis_sing .page_w_host-doc:before {
    left: -50%; }
  .page_decis_sing-app:before {
    left: -50%;
    width: calc(100% + 100%); } }

@media (max-width: 959px) and (min-width: 640px) {
  .page_decis_sing-left {
    order: 2; }
  .page_decis_sing-right {
    order: 1;
    max-width: none; }
    .page_decis_sing-right_wrap {
      padding-left: 0;
      padding-top: 42px; }
  .page_decis_sing-why:before {
    left: -50%; }
  .page_decis_sing-wrap {
    overflow: hidden;
    max-width: 100%; }
  .page_decis_sing-func:before {
    left: -50%; }
  .page_decis_sing .page_w_host-doc-bl:before {
    width: calc(100vw + 100%); }
  .page_decis_sing .page_w_host-doc:before {
    left: -50%; }
  .page_decis_sing-equip-col {
    max-width: none; }
  .page_decis_sing-app:before {
    left: -50%;
    width: calc(100% + 100%); } }

@media (max-width: 639px) {
  .page_decis_sing .demo-section {
    margin: 0 -30px;
    padding: 0 30px; }
  .page_decis_sing-left {
    order: 2; }
  .page_decis_sing-right {
    order: 1;
    max-width: none; }
    .page_decis_sing-right_wrap {
      padding-left: 0;
      padding-top: 42px; }
  .page_decis_sing-why:before {
    left: -50%; }
  .page_decis_sing-wrap {
    overflow: hidden;
    max-width: 100%; }
  .page_decis_sing-func {
    margin: 0 -30px; }
    .page_decis_sing-func-top {
      padding: 0 30px; }
    .page_decis_sing-func:before {
      left: -50%; }
    .page_decis_sing-func-slider .slick-arrow.arrow-prev {
      left: 4px; }
    .page_decis_sing-func-slider .slick-arrow.arrow-next {
      right: 4px; }
  .page_decis_sing .page_w_host-doc-bl:before {
    width: calc(100vw + 100%); }
  .page_decis_sing .page_w_host-doc:before {
    left: -50%; }
  .page_decis_sing-equip-col {
    max-width: none; }
  .page_decis_sing-app:before {
    left: -50%;
    width: calc(100% + 100%); }
  .page_decis_sing-app-ttl {
    font-size: 26px; } }

/*page_decis_sing*/
/*page_equip*/
@media (max-width: 1199px) and (min-width: 960px) {
  .page_equip-col {
    max-width: 50%; } }

@media (max-width: 959px) and (min-width: 640px) {
  .page_equip-col {
    max-width: 100%; } }

@media (max-width: 639px) {
  .page_equip-row {
    margin: 0 -30px; }
  .page_equip-col {
    max-width: 100%;
    border-right: 0; } }

/*page_equip*/
/*page_equip_cat*/
@media (max-width: 1479px) and (min-width: 1200px) {
  .page_equip_cat-left {
    max-width: 804px; }
  .page_equip_cat-list-bl-buy a.btn-gray_bord span, .page_equip_cat-list-bl-more a.btn-gray_bord span {
    font-size: 12px; } }

@media (min-width: 1200px) {
  .page_equip_cat-list-row {
    margin-right: -7px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .page_equip_cat-left {
    order: 2; }
  .page_equip_cat-right {
    order: 1;
    max-width: none; }
    .page_equip_cat-right_wrap {
      padding-left: 0;
      padding-top: 42px; }
  .page_equip_cat-list:before {
    right: auto;
    left: -50%;
    width: calc(100vw + 100%); }
  .page_equip_cat-list-bl {
    max-width: 50%; } }

@media (max-width: 959px) and (min-width: 640px) {
  .page_equip_cat-left {
    order: 2; }
  .page_equip_cat-right {
    order: 1;
    max-width: none; }
    .page_equip_cat-right_wrap {
      padding-left: 0;
      padding-top: 42px; }
  .page_equip_cat-list:before {
    right: auto;
    left: -50%;
    width: calc(100vw + 100%); }
  .page_equip_cat-list-bl {
    max-width: 100%; } }

@media (max-width: 639px) {
  .page_equip_cat-left {
    order: 2; }
  .page_equip_cat-right {
    order: 1;
    max-width: none; }
    .page_equip_cat-right_wrap {
      padding-left: 0;
      padding-top: 42px; }
  .page_equip_cat-list:before {
    right: auto;
    left: -50%;
    width: calc(100vw + 100%); }
  .page_equip_cat-list-row {
    margin: 0 -30px; }
  .page_equip_cat-list-bl {
    max-width: 100%;
    border-right: 0; } }

/*page_equip_cat*/
/*single_prod*/
@media (max-width: 1199px) {
  .single_prod-left {
    max-width: none; }
  .single_prod-wrap {
    background: #fff; }
  .single_prod .page_equip_cat-right {
    position: relative; }
    .single_prod .page_equip_cat-right:before {
      content: '';
      position: absolute;
      top: 0;
      width: calc(100% + 100%);
      height: 100%;
      display: block;
      z-index: 1;
      background: #eaeced;
      left: -50%; }
    .single_prod .page_equip_cat-right .sidebar {
      position: relative;
      z-index: 2; }
  .single_prod-btns-right {
    position: relative; }
    .single_prod-btns-right:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      display: block;
      z-index: 1;
      background: #d8e9f3; }
  .single_prod-txt:before {
    left: -50%;
    width: calc(100% + 100%); }
  .single_prod-tech:before {
    left: -50%;
    width: calc(100% + 100%); }
  .single_prod-main-txt:before {
    right: -15px; }
  .single_prod-btns-left:before {
    right: 0; } }

@media (max-width: 959px) {
  .single_prod-main-left, .single_prod-main-right {
    max-width: none; }
  .single_prod-main-txt:before {
    left: -50%;
    width: calc(100% + 100%); }
  .single_prod-main-left {
    order: 2; }
  .single_prod-main-right {
    order: 1; }
  .single_prod-btns-left, .single_prod-btns-right {
    max-width: none; }
  .single_prod-btns-left:before {
    left: -50%;
    width: calc(100% + 100%); }
  .single_prod-btns-right:before {
    left: -50%;
    width: calc(100% + 100%); }
  .single_prod-adv-bl {
    width: 100%;
    max-width: 245px; }
  .single_prod-adv .row {
    justify-content: center; }
  .single_prod-tech-tbl {
    padding-right: 0; } }

@media (max-width: 639px) {
  .single_prod-tech-tbl .shop_attributes td, .single_prod-tech-tbl .shop_attributes th {
    text-align: left !important;
    display: block;
    width: 100% !important; }
  .single_prod-tech-tbl tr {
    display: block; }
  .single_prod-tech-tbl table.woocommerce-product-attributes.shop_attributes {
    display: block; }
  .single_prod-tech-tbl tbody {
    display: block; }
  .single_prod-tech-tbl .shop_attributes td:first-child, .single_prod-tech-tbl .shop_attributes th:first-child {
    border-bottom: 0 !important; } }

/*single_prod*/
/*page_news*/
@media (min-width: 1200px) {
  .page_news-list-bl-ttl {
    min-height: 84px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .page_news-list-col {
    max-width: 445px; } }

@media (max-width: 959px) {
  .page_news-list-col {
    max-width: none; }
  .page_news-list-bl {
    max-width: none; } }

/*page_news*/
/*single_news*/
@media (max-width: 1479px) and (min-width: 1200px) {
  .single_news-left {
    max-width: 805px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .single_news-left {
    max-width: 100%; }
    .single_news-left_wrap {
      padding-right: 0px; }
      .single_news-left_wrap:before {
        right: auto;
        left: -50%;
        width: calc(100% + 100%); }
  .single_news-right {
    max-width: none; }
    .single_news-right .sidebar {
      display: flex;
      flex-wrap: wrap; }
      .single_news-right .sidebar-menu {
        max-width: 526px; }
        .single_news-right .sidebar-menu ul {
          flex-direction: column; }
      .single_news-right .sidebar-img {
        padding-left: 50px; }
    .single_news-right-back {
      width: 100%; } }

@media (max-width: 959px) {
  .single_news-left {
    max-width: 100%; }
    .single_news-left_wrap {
      padding-right: 0px; }
      .single_news-left_wrap:before {
        right: auto;
        left: -50%;
        width: calc(100% + 100%); }
  .single_news-right {
    max-width: none; }
    .single_news-right .sidebar {
      display: flex;
      flex-wrap: wrap; }
      .single_news-right .sidebar-menu {
        max-width: 100%;
        width: 100%; }
        .single_news-right .sidebar-menu ul {
          flex-direction: column; }
    .single_news-right-back {
      width: 100%; } }

/*single_news*/
/*page_partn*/
@media (max-width: 1479px) and (min-width: 1200px) {
  .page_partn-advan-left:before {
    left: -136px; } }

@media (max-width: 1199px) {
  .page_partn-advan-left {
    max-width: none; }
    .page_partn-advan-left:before {
      width: calc(100% + 60px);
      left: -30px; }
  .page_partn-advan-right {
    max-width: none; }
  .page_partn-advan-bl {
    padding-left: 0; }
  .page_partn-steps-left {
    max-width: none; }
  .page_partn-steps-bl {
    max-width: none; }
  .page_partn-steps-right {
    max-width: none; }
    .page_partn-steps-right:before {
      left: -50%;
      width: calc(100% + 100%); }
  .page_partn-steps-form_wrap {
    padding-left: 0; } }

/*page_partn*/
/*page_cont*/
@media (max-width: 1479px) and (min-width: 1200px) {
  .page_cont-map {
    width: 600px; } }

@media (max-width: 1199px) {
  .page_cont-container:nth-child(2n) .row {
    flex-direction: row; }
  .page_cont-container:nth-child(2n) .page_cont-bl {
    padding-left: 0; }
  .page_cont-form .form-cont-col {
    max-width: none; }
  .page_cont-left, .page_cont-right {
    max-width: none; }
  .page_cont-right {
    height: auto; }
  .page_cont-bl {
    padding-right: 0; }
  .page_cont-map {
    position: static;
    margin: 0 -30px;
    width: calc(100% + 60px); } }

@media (max-width: 639px) {
  .page_cont-right > div {
    margin: 0 -30px; }
  .page_cont-bl-phn a {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%; } }

/*page_cont*/
/*page_tech*/
@media (min-width: 1200px) {
  .page_tech-container:nth-child(2n) .row {
    flex-direction: row-reverse; }
  .page_tech-container:nth-child(2n) .demo-section-bl:before {
    left: auto;
    right: 0; }
  .page_tech-container:nth-child(2n) .demo-section-txt {
    max-width: none;
    padding-left: 50px; } }

@media (max-width: 1199px) {
  .page_tech-info {
    overflow: hidden; }
    .page_tech-info-left {
      max-width: none; }
      .page_tech-info-left:before {
        left: -50%;
        width: calc(100% + 100%); }
    .page_tech-info-form {
      display: flex;
      justify-content: center; }
    .page_tech-info-bl {
      padding: 0;
      margin: 0 auto; }
    .page_tech-info-right {
      max-width: none; }
    .page_tech-info-form {
      margin: 0 -30px;
      flex-direction: column;
      padding: 0 30px; } }

@media (max-width: 639px) {
  .page_tech-info-phn a {
    font-size: 24px;
    line-height: 120%; } }

/*page_tech*/
/*footer*/
@media (max-width: 639px) {
  .f-cont-phn a {
    font-size: 24px;
    line-height: 120%; } }

@media (max-width: 959px) {
  .f-cont, .f-logo {
    width: 100%;
    max-width: none; }
  .f-btn, .f-copy {
    max-width: none; }
  .f-copy {
    order: 4; }
  .f-btn {
    margin-bottom: 43px; } }

/*footer*/
