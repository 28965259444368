html,
body {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: $c_Black;
    font-weight: 300;
    line-height: 1;
    font-family: $f_PTRootUI;
    background: $c_White; }

@media(min-width: 992px) and (max-width: 1199px) {
    html,
    body {
        font-size: 18px; } }

$placeholderSelectors: '::-webkit-input-placeholder' '::-moz-placeholder' ':-ms-input-placeholder' ':-moz-placeholder';
@each $selector in $placeholderSelectors {
    *#{$selector} {
        @include transition(0.3s); } }

* {
    outline: 0; }

main {
    flex-grow: 1; }

svg, svg path, a, button {
    @include transition(0.3s); }

h1 {
    font-size: 28px;
    margin-bottom: 18px;
    line-height: 32px;
    color: #484848;
    font-weight: 400;
    text-transform: none; }

.h1 {
    font-weight: 500;
    font-size: 45px;
    line-height: 57px; }
@media (max-width: 639px) {
    .h1 {
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 26px; } }

h2, .h2 {
    font-family: $f_PTRootUI;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 110%; }
@media (max-width: 1199px) and (min-width: 900px) {
    h2, .h2 {
        font-size: 36px;
        line-height: 110%; } }
@media (max-width: 639px) {
    h2, .h2 {
        font-size: 24px;
        line-height: 120%; } }

a:visited {
    color: inherit; }


img {
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto; }

.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1170px; }
@media (min-width: 1480px) {
    .container {
        max-width: 1370px; } }

@media (max-width: 1199px) and (min-width: 960px) {
    .container {
        max-width: 890px; } }

@media (max-width: 959px) and (min-width: 640px) {
    .container {
        max-width: 610px; } }

@media (max-width: 639px) {
    .container {
        max-width: 100%;
        padding: 0 30px; } }

.row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap; }

.content-box {
    line-height: 1.4;
    h2 {
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 28px; }
    p {
        margin-bottom: 15px; }
    img {
        margin-bottom: 15px; }

    b, strong {
        font-weight: 700; }
    i, em {
        font-style: italic; }

    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px; }
    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 15px; }
    ul {
        font-size: 20px;
        line-height: 140%;
        list-style: none;
        padding: 0;
        margin: 0;
        color: #323232;
        li {
            padding-left: 30px;
            margin-bottom: 22px;
            position: relative;
            &:before {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $c_TorchRed;
                display: block;
                position: absolute;
                left: 0;
                top: 10px; } } }

    ol {
        list-style: decimal;
        padding-left: 20px;
        margin-bottom: 15px; }
    blockquote {
        border: dotted #666 1px;
        border-left: solid $c_VeniceBlue 4px;
        margin: 10px 40px;
        padding: 15px;
        color: #333;
        font-style: italic;
        font-size: 14px;
        background: #fcfcfc;
        .quote a {
            color: #0f5cac;
            font-size: 12px; }

        .quote ul {
            list-style: none; } }

    table {
        thead {
            th {
                border-bottom: 2px solid $c_VeniceBlue;
                padding: 3px 10px; } }
        tbody {
            td {
                border: 1px solid #ccc;
                padding: 3px 10px; } } } }



a {
    &:hover {
        color: $c_VeniceBlue; } }

.btn {
    border: 0;
    padding: 18px 23px 17px;
    width: auto;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    border-radius: 20px;
    cursor: pointer;
    &-arr {
        position: relative;
        padding-right: 49px;
        svg {
            position: absolute;
            right: 23px;
            top: 50%;
            transform: translateY(-50%); } }
    &-red {
        background: $c_TorchRed;
        color: $c_White;
        transition: 0.3s;
        &:visited {
            color: $c_White; }
        &:hover {
            background: $c_VeniceBlue;
            color: $c_White;
            transition: 0.3s;
            svg {
                path {
                    fill: $c_White; } } } }
    &-gray_bord {
        border: 2px solid rgba(6, 88, 136, 0.15);
        border-radius: 20px;
        color: $c_TorchRed;
        transition: 0.3s;
        &:visited {
            color: $c_TorchRed; }
        &:hover {
            border-color: $c_VeniceBlue;
            color: $c_VeniceBlue;
            transition: 0.3s;
            svg {
                path {
                    fill: $c_VeniceBlue; } } } }
    &-white_bord {
        border: 2px solid $c_White;
        border-radius: 20px;
        color: $c_White;
        transition: 0.3s;
        &:visited {
            color: $c_White; }
        &:hover {
            border-color: $c_TorchRed;
            color: $c_TorchRed;
            transition: 0.3s;
            svg {
                path {
                    fill: $c_TorchRed; } } } }
    &-white {
        background: $c_White;
        color: $c_TorchRed;
        transition: 0.3s;
        &:visited {
            color: $c_TorchRed; }
        &:hover {
            background: $c_TorchRed;
            color: $c_White;
            transition: 0.3s;
            svg {
                path {
                    fill: $c_White; } } } } }

@media (max-width: 639px) {
    h2 {
        font-size: 16px;
        line-height: 19px; }
    .content-box {
        h2 {
            margin-bottom: 30px;
            font-size: 16px;
            line-height: 19px; }
        p {
            font-size: 14px;
            line-height: 18px; } } }


input.wpcf7-not-valid {
    border-color: #f00!important; }

input, textarea {
    &.error {
        border-color: #f00; }
    &[type="checkbox"].error {
        box-shadow: 0px 0px 1px 1px #f00; } }

.mb0 {
    margin-bottom: 0 !important; }

.slick-arrow {
    cursor: pointer; }

.top_section {
    background: $c_VeniceBlue;
    padding: 37px 0 31px;
    &-col {
        width: 100%;
        max-width: 50%;
        padding: 0 15px;
        margin-bottom: 10px; }
    &-txt {
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        color: $c_White; }
    &-txt2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        color: $c_White;
        margin-top: 12px;
        p {
            margin: 0; } }
    &-ttl {
        color: $c_White;
        max-width: 600px; }
    &.simp {
        padding: 37px 0;
        .top_section {
            &-ttl {
                margin-bottom: 0; } } } }


@media (max-width: 1199px) {
    .top_section {
        &-col {
            max-width: none; } } }
@media (max-width: 639px) {
    .top_section {
        &-txt {
            font-size: 16px;
            line-height: 155%; } } }

.demo-section {
    background: #F2F4F6;
    overflow: hidden;
    &-bl {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 100px 50px;
        position: relative;
        height: 100%;
        &:before {
            content: '';
            background: url('../img/demo-info.jpg')no-repeat;
            background-size: cover;
            display: block;
            height: 100%;
            width: 720px;
            left: 0;
            top: 0;
            position: absolute;
            z-index: 1; } }
    &-btn {
        position: relative;
        z-index: 2; }
    &-left {
        padding: 43px 15px;
        width: 100%;
        max-width: 50%;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%; }
    &-right {
        width: 100%;
        max-width: 50%; }
    &-txt {
        max-width: 620px; } }

@media (max-width: 1199px) and (min-width: 960px) {
    .demo-section {
        &-left {
            max-width: none; }
        &-txt {
            max-width: none; }
        &-right {
            max-width: none; }
        &-bl {
            &:before {
                left: -35px;
                width: calc(100% + 70px); } } } }

@media (max-width: 959px) and (min-width: 640px) {
    .demo-section {
        &-left {
            max-width: none; }
        &-txt {
            max-width: none; }
        &-right {
            max-width: none; }
        &-bl {
            &:before {
                left: -35px;
                width: calc(100% + 70px); } } } }

@media (max-width: 639px) {
    .demo-section {
        &-left {
            max-width: none; }
        &-txt {
            max-width: none;
            &.content-box {
                & > * {
                    font-size: 20px;
                    line-height: 140%; }
                & h2 {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 120%; } } }
        &-right {
            max-width: none; }
        &-bl {
            padding: 100px 15px;
            &:before {
                left: -35px;
                width: calc(100% + 70px); } } } }

.sidebar {
    &-menu {
        ul {
            & > li {
                margin-bottom: 27px;
                & > a {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 130%;
                    color: #323232;
                    display: inline-block; }
                &.current {
                    & > a {
                        background: #FFFFFF;
                        border-radius: 5px;
                        padding: 2px 7px;
                        color: #065888; } } } } } }

@media(max-width: 1199px) and (min-width: 960px) {
    .sidebar {
        border-bottom: 1px solid #fff;
        &-menu {
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 100%;
                    max-width: 50%; } } } } }

.form {
    &-input, &-textarea {
        margin-bottom: 25px;
        input, textarea {
            width: 100%;
            padding: 13px 20px;
            border-radius: 0;
            border: 1px solid #fff; }
        textarea {
            height: 205px; }
        .wpcf7-not-valid-tip {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #fff;
            background: #f00;
            display: inline-block;
            transform: translateY(-2px);
            padding: 1px 4px 3px; } }
    &-check {
        label {
            input {
                display: none; }
            span.wpcf7-list-item-label {
                color: #fff;
                cursor: pointer;
                padding-left: 22px;
                font-weight: 500;
                font-size: 14px;
                line-height: 129.5%;
                margin-bottom: 25px;
                display: inline-block;
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 3px;
                    left: 0;
                    border: 1px solid #E2E4E6;
                    box-sizing: border-box;
                    border-radius: 2px;
                    width: 12px;
                    height: 12px; }
                a {
                    text-decoration: underline;
                    &:hover {
                        color: $c_TorchRed; } } }
            input:checked {
                & + span.wpcf7-list-item-label {
                    &:before {
                        background-color: $c_White;
                        background-repeat: no-repeat;
                        background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L3 7L1 4' stroke='%23065888' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                        -webkit-background-size: 6px 6px;
                        background-size: 6px 6px;
                        background-position: 50% 50%; } } } }

        .wpcf7-list-item {
            margin-left: 0; } }
    &-file {
        margin-bottom: 17px;
        input {
            color: #fff; }
        span {
            color: #fff;
            margin-bottom: 10px;
            display: block; } } }


.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
    display: none !important; }

.box-modal {
    max-width: 540px;
    margin: 50px auto; }
.modal {
    background: #065888;
    padding: 50px;
    position: relative;
    &-close {
        color: #fff;
        position: absolute;
        top: 14px;
        right: 16px;
        font-size: 40px;
        line-height: 23px;
        height: 23px;
        cursor: pointer; }

    &-form {
        &-ttl {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: #fff;
            margin-bottom: 44px; } } }

.d-none {
    display: none; }

#sb_instagram {
    margin: 0 -5px;
    display: flex;
    flex-wrap: wrap; }

#sb_instagram .sb_instagram_header {
    padding: 5px!important;
    width: 33.3%;
    margin: 0!important; }

#sb_instagram .sb_instagram_header .sbi_header_text {
    display: none; }

#sb_instagram .sb_instagram_header .sbi_header_img {
    float: none;
    margin: 0!important;
    width: 95px;
    height: 95px;
    border-radius: 0; }

.sbi_header_hashtag_icon {
    border-radius: 0; }
