// Fonts
$f_PTRootUI: 'PT Root UI', sans-serif;

/*PTRootUI-Regular*/
@font-face {
    font-family: 'PT Root UI';
    src: url('../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.eot'),
    url('../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.svg'),
    url('../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.woff') format('woff'),
    url('../fonts/PTRootUI/PTRootUI-Regular/PTRootUI-Regular.ttf') format('truetype') {}
    font-weight: normal;
    font-style: normal; }
/*PTRootUI-Regular*/

/*PTRootUI-Medium*/
@font-face {
    font-family: 'PT Root UI';
    src: url('../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.eot'),
    url('../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.svg'),
    url('../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.woff') format('woff'),
    url('../fonts/PTRootUI/PTRootUI-Medium/PTRootUI-Medium.ttf') format('truetype') {}
    font-weight: 500;
    font-style: normal; }
/*PTRootUI-Medium*/

/*PTRootUI-Bold*/
@font-face {
    font-family: 'PT Root UI';
    src: url('../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.eot'),
    url('../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.svg'),
    url('../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.woff') format('woff'),
    url('../fonts/PTRootUI/PTRootUI-Bold/PTRootUI-Bold.ttf') format('truetype') {}
    font-weight: 700;
    font-style: normal; }
/*PTRootUI-Bold*/

/*PTRootUI-Light*/
@font-face {
    font-family: 'PT Root UI';
    src: url('../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.eot'),
    url('../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.svg'),
    url('../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.woff') format('woff'),
    url('../fonts/PTRootUI/PTRootUI-Light/PTRootUI-Light.ttf') format('truetype') {}
    font-weight: 300;
    font-style: normal; }
/*PTRootUI-Light*/
