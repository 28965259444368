/*header*/
.h {
    &-logo {
        display: block;
        padding-right: 51px;
        padding-top: 37px;
        padding-left: 15px;
        padding-bottom: 8px; }
    &-cont {
        flex: 1;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 15px;
        padding-bottom: 8px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        flex-wrap: wrap; }
    &-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px; }
    &-soc {
        display: flex;
        flex-wrap: wrap;
        margin-right: 11px;
        margin-bottom: 12px;
        a {
            margin-right: 15px;
            margin-bottom: 10px; } }
    &-info {
        display: flex;
        flex-wrap: wrap;
        padding-right: 15px;
        flex: 1; }
    &-phn {
        margin-top: 4px;
        margin-bottom: 14px;
        a {
            color: $c_White; } }
    &-menu {
        width: 100%;
        .sub-menu {
            z-index: -9999;
            opacity: 0;
            transition: opacity 0.3s;
            position: absolute;
            background: #fff;
            padding: 10px 6px 0;
            border-radius: 5px;
            display: block;
            pointer-events: none;
            width: max-content;
            li {
                padding: 0 6px 10px;
                a {
                    color: $c_VeniceBlue;
                    line-height: 130%; }
                &.current-menu-item {
                    a {
                        color: $c_TorchRed; } } } }
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 -15px;
            li {
                padding: 0 15px 20px;
                &:first-child {
                    .sub-menu {
                        left: 30px; } }
                &:last-child {
                    .sub-menu {
                        left: auto;
                        right: 0; } }
                &.menu-item-has-children {
                    position: relative;
                    padding-right: 20px;
                    &:before, .click {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        right: 0;
                        top: 8px; }
                    &:before {
                        border-bottom: 2px solid $c_White;
                        border-right: 2px solid $c_White;
                        transform: rotate(45deg);
                        z-index: 2; }
                    .click {
                        z-index: 3;
                        width: 13px;
                        height: 13px;
                        right: -2px;
                        top: 6px; } }
                a {
                    color: $c_White;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 155%;
                    padding: 0 6px;
                    display: inline-block; }

                &.current-menu-item, &.current-menu-parent {
                    a {
                        background: $c_White;
                        color: $c_VeniceBlue;
                        border-radius: 5px; }
                    &.menu-item-has-children {
                        &:before {
                            border-color: $c_VeniceBlue; } } }
                &.hovered {
                    .sub-menu {
                        z-index: 1;
                        opacity: 1;
                        transition: opacity 0.3s;
                        pointer-events: all; } } } } }
    &-auth {
        margin-bottom: 24px;
        a {
            padding: 4px 0px 5px 35px;
            display: inline-block;
            color: $c_White;
            background: url('../img/auth.svg')no-repeat 0 50%; } }
    &-btn {
        display: none;
        padding: 7px 15px 0;
        button {
            background: #D8E9F3;
            border-radius: 20px;
            width: 48px;
            padding: 15px 12px;
            border: 0;
            margin-left: 15px;
            span {
                background: #065888;
                border-radius: 2px;
                width: 100%;
                height: 2px;
                display: block;
                margin: 5px 0;
                &:first-child {
                    margin-top: 0; }
                &:last-child {
                    margin-bottom: 0; } } } }
    &-mob {
        display: none; } }

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    &.static {
        background: #F2F4F6;
        position: static;
        .h {
            &-logo {
                background: $c_White;
                position: relative;
                svg {
                    path[fill="white"] {
                        fill: $c_Black; } }
                &:before {
                    content: '';
                    position: absolute;
                    right: 0;
                    width: 50vw;
                    height: 100%;
                    background: $c_White;
                    z-index: 1;
                    top: 0; }
                svg {
                    position: relative;
                    z-index: 2; } }
            &-phn, &-auth, &-menu ul li {
                a {
                    color: $c_Black; } }
            &-auth {
                a {
                    background: url('../img/auth_white.svg')no-repeat 0 50%; } }
            &-menu {
                ul {
                    li {
                        &.menu-item-has-children {
                            &:before {
                                border-color: $c_VeniceBlue; } } } }
                .sub-menu {
                    li {
                        a {
                            color: $c_VeniceBlue; }
                        &.current-menu-item {
                            a {
                                color: $c_TorchRed; } } } } }
            &-soc {
                a {
                    svg {
                        path {
                            fill: #B0B1B2; } } } } }
        path#path4684 {
            fill: #f2f4f6!important; }


        path#path4680 {
            fill: #b0b1b2!important; } } }

/*header*/

.video-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    video {
        min-height: calc(100% + 172px); } }

/*main page*/
.main {
    &-first {
        position: relative;
        padding-top: 161px;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: 50% 0;
        padding-bottom: 60px;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: #323232;
            opacity: 0.6;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block; }
        .container {
            position: relative;
            z-index: 3; }
        &-bl {
            max-width: 860px; }
        &-ttl {
            font-weight: 500;
            font-size: 45px;
            line-height: 57px;
            color: $c_White;
            margin-bottom: 38px; } }
    &-numbers {
        overflow: hidden;
        &-col {
            padding: 0 15px 30px;
            width: 100%;
            max-width: 335px;
            overflow: hidden; }
        &-left {
            padding: 35px 15px 14px;
            width: 100%;
            max-width: 1005px; }
        &-right {
            padding: 0 15px;
            width: 100%;
            flex: 1;
            position: relative; }
        &-bl {
            &-ttl {
                font-size: 54px;
                line-height: 68px;
                display: flex;
                align-items: center;
                color: $c_TorchRed;
                font-weight: 600; }
            &-txt {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #323232; } }
        &-one {
            padding: 35px 50px 43px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            height: 100%;
            &:before {
                content: '';
                width: 385px;
                position: absolute;
                background: url(../img/one-back.jpg) no-repeat 0% 0%;
                background-size: cover;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 1; }

            &-ttl {
                font-style: normal;
                font-weight: normal;
                font-size: 54px;
                line-height: 68px;
                display: flex;
                align-items: center;
                color: $c_White;
                position: relative;
                z-index: 2; }
            &-txt {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: $c_White;
                position: relative;
                z-index: 2; } } }
    &-hot {
        background: #F2F4F6;
        &-slider {
            position: relative;
            .slick-arrow {
                position: absolute;
                top: 48px;
                cursor: pointer;
                z-index: 3;
                &.arrow-prev {
                    left: 41.3%; }
                &.arrow-next {
                    left: 45.6%; } } }

        &-row {
            display: flex;
            flex-wrap: wrap; }
        &-slide {
            &-left {
                width: 100%;
                max-width: 720px;
                padding: 46px 0;
                display: flex;
                flex-direction: column; }
            &-top {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #B0B1B3;
                margin-bottom: 30px; }
            &-txt {
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: $c_VeniceBlue;
                flex: 1;
                padding-right: 25px; }
            &-right {
                position: relative;
                flex: 1; } } }
    &-revs {
        overflow: hidden;
        background: #fff;
        &-left {
            position: relative;
            width: 100%;
            max-width: 350px;
            padding: 39px 15px;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                display: block;
                left: -35px;
                height: 100%;
                width: calc(100% + 35px);
                background: $c_VeniceBlue; } }
        &-ttl {
            position: relative;
            z-index: 2;
            color: $c_White; }
        &-right {
            flex: 1;
            max-width: calc(100% - 350px);
            &-top {
                background: $c_White;
                padding: 74px 0 34px;
                overflow: hidden;
                margin-right: -34px; }
            &-btm {
                padding: 46px 28px 46px 51px;
                position: relative; } }
        &-slider {
            max-width: 236px;
            .slick-list.draggable {
                overflow: visible; }
            &_text {
                .slick-arrow {
                    position: absolute;
                    top: 0;
                    cursor: pointer;
                    z-index: 2;
                    &.arrow-prev {
                        right: 5%; }


                    &.arrow-next {
                        right: -1%; } } } }
        &-slide {
            padding: 0 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            &_text {
                &-top {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 155%;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #B0B1B3;
                    margin-bottom: 30px; }
                &-txt {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 120%;
                    color: #065888;
                    max-width: 966px;
                    margin-bottom: 37px;
                    min-height: 184px; }

                &-file {
                    a {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 155%;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                        color: #065888;
                        display: flex;
                        align-items: center;
                        svg {
                            margin-right: 15px; } } } } } }
    &-news {
        background: #E2E4E6;
        padding: 46px 0 51px;
        &-ttl {
            margin-bottom: 32px;
            flex: 1; }
        &-top {
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap; }
        &-more {
            padding-top: 6px;
            a {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #F90936;
                svg {
                    margin-left: 8px; } } }
        &-row {
            display: flex;
            flex-wrap: wrap; }
        &-bl {
            background: #fff;
            display: block;
            padding: 43px 50px;
            position: relative;
            &-img {
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                display: block;
                -webkit-background-size: cover;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                transition: 0.3s;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    background: #065888;
                    opacity: 0.6; } }
            &-date {
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                color: #B0B1B3;
                margin-bottom: 2px;
                position: relative;
                z-index: 2; }
            &-ttl {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                display: flex;
                align-items: flex-end;
                color: #323232;
                display: block;
                min-height: 110px;
                margin-bottom: 25px;
                position: relative;
                z-index: 2; }
            &-cat {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #065888;
                position: relative;
                z-index: 2;
                &:visited {
                    color: #065888; } }
            &:hover {
                background: #065888;
                .main-news-bl {
                    &-img {
                        opacity: 1;
                        transition: 0.3s; }
                    &-date, &-ttl, &-cat {
                        color: #fff; } } } }
        &-col {
            width: 50%;
            &:first-child {
                .main-news-bl {
                    border-right: 2px solid #e2e4e6; } } } } }

/*main page*/


/*page_about*/
.page_about {
    &-first {
        position: relative;
        padding-top: 161px;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: 50% 0;
        padding-bottom: 60px;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: #323232;
            opacity: 0.6;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block; }
        .container {
            position: relative;
            z-index: 2; }
        &-bl {
            max-width: 860px; }
        &-ttl {
            font-weight: 500;
            font-size: 45px;
            line-height: 57px;
            color: $c_White;
            margin-bottom: 28px; }
        &-txt {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: #FFFFFF;
            max-width: 650px;
            margin-bottom: 28px; } }
    &-hist {
        padding: 38px 0 31px;
        &-left {
            padding: 0 15px;
            width: 100%;
            max-width: 670px; }
        &-right {
            padding: 0 15px;
            width: 100%;
            max-width: 700px; }
        &-ttl {
            margin-bottom: 20px; }
        &-cont {
            padding-top: 3px;
            margin-bottom: 20px;
            &-ttl {
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: #065888;
                margin-bottom: 48px; }
            &-txt {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                color: #323232; } } }
    &-dates {
        background: #F2F4F6;
        padding: 36px 0 18px;
        overflow: hidden;
        &-slider {
            &_wrap {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    z-index: 1;
                    display: block;
                    background: #B0B1B3;
                    top: 95px; } }
            max-width: 1005px;
            position: static;
            z-index: 2;
            & > * {
                z-index: 2; }
            .slick-arrow {
                position: absolute;
                top: 12px;
                z-index: 3;
                cursor: pointer;
                &.arrow-prev {
                    right: 50px; }
                &.arrow-next {
                    right: 0%; } }
            .slick-list.draggable {
                overflow: visible; } }

        &-bl {
            &-date {
                display: flex;
                align-items: center;
                margin-bottom: 27px;
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: #B0B1B3;
                height: 57px; }
            &-circ {
                width: 23px;
                height: 23px;
                margin-bottom: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:before {
                    content: '';
                    display: block;
                    border-radius: 50%;
                    width: 11px;
                    height: 11px;
                    background: #B0B1B3; } }
            &-txt {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                display: flex;
                color: #323232;
                margin-bottom: 69px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center; }
            &-quan {
                font-weight: 500;
                font-size: 36px;
                line-height: 110%;
                display: flex;
                align-items: center;
                color: #B0B1B3; }
            &-txt2 {
                font-weight: 500;
                font-size: 11px;
                line-height: 160%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #B0B1B3; } }
        .slick-current {
            .page_about-dates-bl {
                &-date {
                    font-size: 45px;
                    line-height: 57px;
                    color: #F90936; }
                &-circ {
                    &:before {
                        width: 23px;
                        height: 23px;
                        background: #F90936; } } } } }
    &-info {
        overflow: hidden;
        &-ttl {
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #B0B1B3;
            margin-bottom: 43px; }
        &-left {
            padding: 0 15px;
            padding-top: 44px;
            width: 100%;
            max-width: 670px; }
        &-right {
            padding: 0 15px;
            width: 100%;
            max-width: 700px; }
        &-directors {
            display: flex;
            margin: 0 -15px;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 540px;
            &-bl {
                padding: 0 15px;
                margin-bottom: 47px;
                &-img {
                    margin-bottom: 12px;
                    overflow: hidden;
                    border-radius: 50%;
                    max-width: 173px;
                    width: 100%;
                    height: 173px; }
                &-name {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 140%;
                    color: #065888; } } }
        &-bl {
            padding: 42px 50px;
            padding-right: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 627px;
            position: relative;
            height: 100%;
            &:before {
                content: '';
                width: 720px;
                height: 100%;
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                display: block;
                background: url('../img/about-info.jpg');
                -webkit-background-size: cover;
                background-size: cover; }
            &-ttl {
                font-weight: 500;
                font-size: 36px;
                line-height: 110%;
                color: #FFFFFF;
                position: relative;
                z-index: 2;
                margin-bottom: 26px; }
            &-txt {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #FFFFFF;
                position: relative;
                z-index: 2; } } }
    &-empl {
        padding: 50px 0 0;
        background: #F2F4F6;
        &-bl {
            padding: 0 15px;
            margin-bottom: 47px;
            width: 100%;
            max-width: 335px;
            &-img {
                margin-bottom: 12px;
                overflow: hidden;
                border-radius: 50%;
                max-width: 173px;
                width: 100%;
                height: 173px; }
            &-name {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #065888;
                margin-bottom: 9px; }
            &-pos {
                font-weight: 500;
                font-size: 11px;
                line-height: 160%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #B0B1B3; } } }
    &-sert {
        overflow: hidden;
        &-left {
            padding: 50px 15px 48px;
            max-width: 1005px;
            width: 100%; }
        &-right {
            padding: 0 15px;
            width: 100%;
            max-width: 365px;
            &-bl {
                padding: 42px 50px;
                padding-right: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                height: 100%;
                &:before {
                    content: '';
                    width: 385px;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    display: block;
                    background: #065888; } } }
        &-ttl {
            position: relative;
            z-index: 2;
            font-weight: 500;
            color: #FFFFFF; }
        &-arrs {
            position: relative;
            z-index: 2;
            display: flex;
            width: 76px;
            justify-content: space-between;
            button {
                background: none;
                border: 0;
                padding: 0;
                cursor: pointer; } }
        &-slide {
            padding-right: 15px; } }
    &-partn {
        background: #F2F4F6;
        overflow: hidden;
        &-left {
            padding: 43px 15px;
            width: 100%;
            max-width: 671px; }
        &-right {
            padding: 0 15px;
            width: 100%;
            max-width: 699px; }
        &-txt {
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            width: 100%;
            max-width: 600px; }
        &-btn {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 720px;
                height: 100%;
                background: #D8E9F3;
                display: block;
                z-index: 1; }
            a {
                position: relative;
                z-index: 2; } } }
    &-team {
        background: #F2F4F6;
        overflow: hidden;
        &-left {
            padding: 0 15px;
            width: 100%;
            max-width: 700px; }
        &-right {
            padding: 46px 15px;
            width: 100%;
            max-width: 670px; }
        &-img {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                height: 540px;
                width: 720px;
                background: url('../img/about-team.jpg');
                -webkit-background-size: cover;
                background-size: cover;
                left: -50px; } }
        &-bl {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            min-height: 446px; }
        &-ttl {
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #B0B1B3;
            margin-bottom: 30px; }
        &-txt {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: #065888;
            margin-bottom: 20px;
            flex: 1; } } }

/*page_about*/

/*page_serv*/
.page_serv {
    background: #F2F4F6;
    &-row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2px;
        &-left {
            width: 100%;
            max-width: 50%;
            position: relative; }
        &-img {
            height: 480px;
            width: calc(100% + 50px);
            margin-left: -50px; }
        &-right {
            max-width: 50%;
            width: 100%;
            padding: 50px 0 43px 44px;
            display: flex;
            flex-direction: column; }
        &-num {
            position: absolute;
            z-index: 2;
            font-size: 130px;
            line-height: 164px;
            color: $c_White;
            top: 12px;
            left: 0px; }
        &-ttl {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: $c_VeniceBlue;
            margin-bottom: 26px; }
        &-txt {
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            color: #323232;
            flex: 1;
            padding-bottom: 20px; }
        &-btn {
            a {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: $c_TorchRed; } } } }

/*page_serv*/

/*page_w_sys*/
.page_w_sys {
    &-numbers {
        padding: 35px 0;
        &-col {
            padding: 0 15px 10px;
            width: 100%;
            max-width: 335px;
            overflow: hidden; }
        &-bl {
            &-ttl {
                font-size: 54px;
                line-height: 68px;
                display: flex;
                align-items: center;
                color: $c_TorchRed; }
            &-txt {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #323232; } }
        &-one {
            padding: 35px 50px 43px;
            display: flex;
            flex-direction: column;
            height: 357px;
            justify-content: space-between;
            width: 385px;
            position: absolute;
            background: url('../img/one-back.jpg')no-repeat 0% 0%;
            -webkit-background-size: cover;
            background-size: cover;
            &-ttl {
                font-style: normal;
                font-weight: normal;
                font-size: 54px;
                line-height: 68px;
                display: flex;
                align-items: center;
                color: $c_White; }
            &-txt {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: $c_White; } } }
    &-team {
        background: #F2F4F6;
        overflow: hidden;
        &-left {
            padding: 0 15px;
            width: 100%;
            max-width: 700px; }
        &-right {
            padding: 46px 15px;
            width: 100%;
            max-width: 670px; }
        &-img {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                height: 633px;
                width: 720px;
                background: url('../img/system_about.jpg');
                -webkit-background-size: cover;
                background-size: cover;
                left: -50px; } }
        &-bl {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            min-height: 446px; }
        &-ttl {
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #B0B1B3;
            margin-bottom: 30px; }
        &-txt {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: #065888;
            margin-bottom: 20px;
            flex: 1; } }
    &-sph {
        padding: 39px 0 50px;
        &-ttl {
            margin-bottom: 47px; }
        .row {
            margin: 1px; }
        &-bl {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 333px;
            padding: 41px 48px;
            &-img {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                display: block;
                opacity: 0;
                top: 0;
                left: 0;
                background: $c_VeniceBlue;
                -webkit-background-size: cover;
                background-size: cover;
                transition: 0.3s; }
            &-ttl {
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: $c_VeniceBlue;
                position: relative;
                flex: 1;
                z-index: 2;
                transition: 0.3s; }
            &:hover {
                .page_w_sys-sph-bl {
                    &-img {
                        opacity: 1;
                        transition: 0.3s; }
                    &-ttl {
                        color: $c_White;
                        transition: 0.3s; } } } }
        &-col {
            width: 100%;
            max-width: 25%;
            background: #F2F4F6;
            border: 1px solid #fff;
            margin: -1px;
            &.big {
                max-width: 50%;
                .page_w_sys-sph {
                    &-bl {
                        background: $c_VeniceBlue;
                        &-ttl {
                            color: $c_White; } } } } } }
    &-prod {
        background: #F2F4F6;
        &-col {
            width: 100%;
            max-width: 50%;
            padding: 50px 15px;
            &:first-child {
                border-right: 1px solid $c_White; }
            &:last-child {
                padding-left: 50px; } }
        &-bl {
            &-img {
                margin-bottom: 32px; }
            &-txt {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                margin-bottom: 109px;
                max-width: 619px; } } }
    &-info {
        padding: 42px 0 50px;
        &-left {
            padding: 0 15px;
            width: 100%;
            max-width: 50%; }
        &-right {
            padding: 0 15px;
            width: 100%;
            max-width: 50%; }
        &-top {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: #065888;
            margin-bottom: 26px; }
        &-txt {
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            color: #323232;
            margin-bottom: 43px; }
        &-ttl {
            margin-bottom: 26px;
            max-width: 620px; } }
    &-cats {
        background: #F2F4F6;
        padding: 50px 0 23px;
        &-col {
            max-width: 25%;
            width: 100%;
            padding: 0 15px;
            margin-bottom: 20px; }
        &-bl {
            width: 100%;
            max-width: 298px;
            display: block;
            &-img {
                margin-bottom: 24px;
                display: block;
                text-align: center;
                font-size: 0; }
            &-txt {
                font-size: 20px;
                line-height: 140%;
                color: #323232;
                display: block; } } }
    &-goto {
        background: #065888;
        padding: 43px 0;
        &-link {
            font-size: 16px;
            line-height: 155%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #FFFFFF;
            &:visited {
                color: #FFFFFF; }
            &:hover {
                color: $c_TorchRed;
                svg {
                    path {
                        fill: $c_TorchRed; } } } } }
    &-why {
        background: #F2F4F6;
        padding-top: 42px;
        &-top {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 23px;
            align-items: center; }
        &-ttl {
            flex: 1;
            margin-bottom: 20px; }
        &-more {
            margin-bottom: 20px;
            a {
                font-size: 16px;
                line-height: 155%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: $c_TorchRed;
                &:hover {
                    color: $c_VeniceBlue;
                    svg {
                        path {
                            fill: $c_VeniceBlue; } } } } }
        &-col {
            width: 100%;
            max-width: 456px;
            padding: 0 15px 49px; }
        &-bl {
            padding-top: 36px;
            border-top: 2px solid #E2E4E6;
            &-ttl {
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: #065888;
                margin-bottom: 23px; }
            &-txt {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                color: #323232; } } } }

/*page_w_sys*/

/*page_w_host*/
.page_w_host {
    .demo-section {
        background: $c_White; }
    &-adv {
        padding: 42px 0;
        background: #F2F4F6;
        &-ttl {
            margin-bottom: 43px; }
        &-right {
            max-width: 923px;
            width: 100%;
            padding: 0 15px; }
        &-left {
            flex: 1;
            padding: 0 15px; }
        &-bl {
            display: flex;
            flex-direction: column;
            background: $c_White;
            padding: 46px 50px;
            &-ttl {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #B0B1B3;
                margin-bottom: 30px; }
            &-txt {
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: #065888;
                margin-bottom: 20px;
                flex: 1;
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    li {
                        width: 50%; } } }
            &.unstand {
                background: none;
                padding-left: 0;
                padding-right: 0;
                .page_w_host-adv-bl {
                    &-txt {
                        ul {
                            li {
                                width: 100%; } } } } } }
        &-btm {
            width: 100%;
            padding: 0 15px;
            border-top: 2px solid #F2F4F6;
            .content-box {
                ul {
                    li {
                        &:before {
                            background: none;
                            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1C7 0.447754 6.55228 0 6 0C5.44772 0 5 0.447754 5 1V5H1C0.447723 5 0 5.44775 0 6C0 6.55225 0.447723 7 1 7H5V11C5 11.5522 5.44772 12 6 12C6.55228 12 7 11.5522 7 11V7H11C11.5523 7 12 6.55225 12 6C12 5.44775 11.5523 5 11 5H7V1Z' fill='%2324BE08'/%3E%3C/svg%3E%0A");
                            -webkit-background-size: cover;
                            background-size: cover;
                            width: 12px;
                            height: 12px;
                            top: 8px; } } } } } }
    &-sph {
        padding: 39px 0 50px;
        &-ttl {
            margin-bottom: 47px; }
        .row {
            margin: 1px; }
        &-bl {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 333px;
            padding: 41px 48px;
            &-img {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                display: block;
                opacity: 0;
                top: 0;
                left: 0;
                background: $c_VeniceBlue;
                -webkit-background-size: cover;
                background-size: cover;
                transition: 0.3s; }
            &-ttl {
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: $c_VeniceBlue;
                position: relative;
                flex: 1;
                z-index: 2;
                transition: 0.3s; }
            &-ico {
                background: $c_White;
                border-radius: 10px;
                width: 60px;
                height: 60px;
                padding: 10px;
                position: relative;
                z-index: 2; }
            &:hover {
                .page_w_host-sph-bl {
                    &-img {
                        opacity: 1;
                        transition: 0.3s; }
                    &-ttl {
                        color: $c_White;
                        transition: 0.3s; } } } }

        &-col {
            width: 100%;
            max-width: 33.3%;
            background: #F2F4F6;
            border: 1px solid #fff;
            margin: -1px; } }
    &-doc {
        background: #F2F4F6;
        overflow: hidden;
        &-left {
            padding: 0 15px;
            width: 100%;
            max-width: 50%; }
        &-right {
            padding: 0 15px;
            width: 100%;
            max-width: 50%; }
        &-bl {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 43px 0;
            position: relative;
            &:before {
                content: '';
                height: 100%;
                width: 100vw;
                position: absolute;
                top: 0;
                left: 0;
                background: #065888; } }
        &-txt {
            padding: 43px 0;
            max-width: 620px;
            font-weight: 500;
            font-size: 20px;
            line-height: 140%; }
        &-btn {
            position: relative;
            z-index: 2; } } }

/*page_w_host*/

/*page_w_mob*/
.page_w_mob {
    .demo-section {
        background: $c_White; }
    &-app {
        padding-top: 50px;
        background: #F2F4F6;
        &-left {
            width: 100%;
            max-width: 700px;
            padding: 0 15px;
            margin-bottom: 50px; }
        &-right {
            max-width: 670px;
            width: 100%;
            padding: 0 15px; }
        &-bl {
            padding: 41px 50px 50px;
            background: #fff;
            border-top: 2px solid #f2f4f6;
            &:first-child {
                border-top: 0; }
            &-top {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #B0B1B3; }
            &-ttl {
                font-weight: 500;
                font-size: 36px;
                line-height: 110%;
                color: #065888;
                margin-bottom: 38px; } }
        &-btns {
            display: flex;
            flex-wrap: wrap;
            a {
                margin-right: 25px;
                font-size: 0;
                &:last-child {
                    margin-right: 0; } } }
        &-img {
            display: flex;
            height: 100%;
            align-items: flex-end;
            justify-content: center;
            .sm {
                display: none; } } }
    &-why {
        padding-top: 42px;
        background: $c_White;
        .page_w_sys-why {
            &-top {
                justify-content: space-between; }
            &-ttl {
                max-width: 893px; } } } }
/*page_w_mob*/

/*page_decis*/
.page_decis {
    &-wrap {
        padding: 39px 0 50px;
        background: #F2F4F6; }
    .row {
        margin: 1px; }
    &-bl {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 333px;
        padding: 41px 48px;
        &-img {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: block;
            opacity: 0;
            top: 0;
            left: 0;
            background: $c_VeniceBlue;
            -webkit-background-size: cover;
            background-size: cover;
            transition: 0.3s; }
        &-ttl {
            font-weight: 500;
            color: $c_VeniceBlue;
            position: relative;
            flex: 1;
            z-index: 2;
            transition: 0.3s;
            .ttl {
                font-size: 24px;
                line-height: 120%;
                display: block;
                margin-bottom: 5px; }
            .txt {
                font-size: 20px;
                line-height: 140%;
                display: block; } }
        &-ico {
            background: $c_White;
            border-radius: 10px;
            width: 60px;
            height: 60px;
            padding: 10px;
            position: relative;
            z-index: 2; }
        &:hover {
            .page_decis-bl {
                &-img {
                    opacity: 1;
                    transition: 0.3s; }
                &-ttl {
                    color: $c_White;
                    transition: 0.3s; } } } }
    &-col {
        width: 100%;
        max-width: 50%;
        background: $c_White;
        border: 1px solid #F2F4F6;
        margin: -1px; } }
/*page_decis*/

/*page_decis_sing*/
.page_decis_sing {
    background: #f2f4f6;

    &-right {
        width: 100%;
        max-width: 365px;
        padding: 0 15px;
        &_wrap {
            padding-left: 50px;
            padding-top: 50px; } }
    &-left {
        width: 100%;
        max-width: 1005px;
        padding: 0 15px;
        .container {
            padding: 0; } }
    &-why {
        padding-top: 42px;
        background: #fff;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            display: block;
            z-index: 1;
            top: 0;
            right: 0;
            width: calc(100vw + 100%);
            background: $c_White;
            height: 100%; }
        .container {
            position: relative;
            z-index: 2; }
        .page_w_sys-why {
            &-top {
                padding-right: 30px; }
            &-col {
                max-width: 490px; } } }
    &-func {
        padding: 42px 0 50px;
        background: #D8E9F3;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            display: block;
            z-index: 1;
            top: 0;
            right: 0;
            width: calc(100vw + 100%);
            background: #D8E9F3;
            height: 100%; }
        .container {
            position: relative;
            z-index: 2;
            overflow: hidden; }
        &-top {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 23px;
            align-items: center; }
        &-ttl {
            flex: 1;
            margin-bottom: 20px; }
        &-more {
            margin-bottom: 20px;
            padding-right: 30px;
            a {
                font-size: 16px;
                line-height: 155%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: $c_TorchRed;
                &:hover {
                    color: $c_VeniceBlue;
                    svg {
                        path {
                            fill: $c_VeniceBlue; } } } } }
        &-slider {
            max-width: 445px;
            width: 100%;
            position: static;
            .slick-list.draggable {
                overflow: visible; }
            .slick-arrow {
                background: $c_White;
                border-radius: 50%;
                padding: 10px 10px;
                width: 40px;
                height: 40px;
                position: absolute;
                top: 50%;
                margin-top: -20px;
                &.arrow-prev {
                    left: 20px;
                    z-index: 3; }
                &.arrow-next {
                    left: auto;
                    right: 20px;
                    z-index: 3; } } }

        &-bl {
            border-right: 1px solid #d8e9f3;
            background: $c_White;
            &-img {
                background: #F2F4F6;
                padding: 59px 50px;
                img {
                    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.15)); } }
            &-wrap {
                padding: 44px 50px;
                background: $c_White; }
            &-ttl {
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: $c_VeniceBlue;
                margin-bottom: 23px; }
            &-txt {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%; } } }
    .page_w_host-doc {
        background: $c_White;
        position: relative;
        overflow: visible;
        &:before {
            content: '';
            position: absolute;
            display: block;
            z-index: 1;
            top: 0;
            right: 0;
            width: calc(100vw + 100%);
            background: $c_White;
            height: 100%; }
        .container {
            position: relative;
            z-index: 2; }
        &-bl {
            &:before {
                width: 100%; } } }
    &-equip {
        padding-top: 43px;
        &-ttl {
            margin-bottom: 43px; }
        &-col {
            margin-bottom: 73px;
            width: 100%;
            max-width: 50%;
            padding: 0 15px; }
        &-bl {
            max-width: 452px;
            &-img {
                margin-bottom: 39px; }
            &-ttl {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #323232;
                margin-bottom: 15px; }
            &-txt {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%; } } }
    &-goto {
        position: relative;
        .container {
            position: relative;
            z-index: 2; }
        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100vw;
            right: 0;
            top: 0;
            z-index: 1;
            background: #065888; } }
    &-app {
        position: relative;
        padding-bottom: 50px;
        background: #d8e9f3;
        .container {
            position: relative;
            z-index: 2;
            padding: 0;
            max-width: 955px;
            margin: 0;
            background: #fff;
            padding: 41px 30px 40px; }
        &:before {
            position: absolute;
            z-index: 1;
            content: '';
            width: 100vw;
            right: 0;
            top: 0;
            height: 100%;
            display: block;
            background: #d8e9f3; }
        &-top {
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #B0B1B3; }
        &-ttl {
            font-weight: 500;
            font-size: 36px;
            line-height: 110%;
            text-align: center;
            color: #065888;
            margin-bottom: 38px; }
        &-btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            a {
                margin: 0 13px 10px; } } } }
/*page_decis_sing*/

/*page_equip*/
.page_equip {
    &-wrap {
        background: #F2F4F6;
        padding: 50px 0 43px; }
    &-row {
        background: #FFFFFF;
        display: flex;
        flex-wrap: wrap; }
    &-col {
        padding: 44px 50px;
        border-right: 1px solid #f2f4f6;
        border-bottom: 1px solid #f2f4f6;
        width: 100%;
        max-width: 25%;
        &-ttl {
            display: block;
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            margin-bottom: 16px;
            span {
                color: #065888; } } }
    &-list {
        li {
            display: block;
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            margin-bottom: 15px;
            a {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                text-decoration: underline;
                span {
                    color: #323232; } } } }
    &-txt {
        font-weight: 500;
        font-size: 16px;
        line-height: 155%; } }
/*page_equip*/

/*page_equip_cat*/
.page_equip_cat {
    background: #f2f4f6;
    &-wrap {
        overflow: hidden; }
    &-right {
        width: 100%;
        max-width: 365px;
        padding: 0 15px;
        &_wrap {
            padding-left: 50px;
            padding-top: 50px; } }
    &-left {
        width: 100%;
        max-width: 1005px;
        padding: 0 15px;
        .container {
            padding: 0; } }
    &-list {
        position: relative;
        &-row {
            position: relative;
            z-index: 2;
            display: flex;
            flex-wrap: wrap;
            padding: 1px 0; }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 6px;
            width: 100vw;
            height: 100%;
            background: #fff;
            display: block; }
        &-bl {
            border: 2px solid #f2f4f6;
            width: 100%;
            max-width: 33.3%;
            padding: 50px;
            margin: -1px;
            display: flex;
            flex-direction: column;
            &-img {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 33px; }
            &-ttl {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #065888;
                display: block;
                &:visited {
                    color: #065888; } }
            &-txt {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                color: #323232;
                margin-top: 15px;
                flex: 1; }

            &-more, &-buy {
                margin-top: 12px;
                a.btn-gray_bord {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    span {
                        flex: 1; } } }
            &-more {
                margin-top: 33px;
                a.btn-gray_bord {
                    color: #065888; } } } }
    &-right {
        .sidebar {
            padding: 43px 0;
            &-menu {
                & > ul {
                    & > li {
                        & > a {
                            padding-right: 22px;
                            position: relative;
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                border-bottom: 2px solid #323232;
                                border-right: 2px solid #323232;
                                width: 7px;
                                height: 7px;
                                right: 5px;
                                top: 6px;
                                transform: rotate(45deg); } }
                        &.current {
                            & > a:before {
                                transform: rotate(-135deg);
                                top: 10px; } } } } } }
        .sublist {
            padding-top: 15px; } } }
/*page_equip_cat*/

/*single_prod*/
.single_prod {
    &-wrap {
        overflow: hidden;
        background: #EAECED; }
    &-right {
        width: 100%;
        max-width: 365px;
        padding: 0 15px;

        &_wrap {
            padding-left: 50px;
            padding-top: 50px; } }
    &-left {
        width: 100%;
        max-width: 1005px;
        padding: 0 15px;
        position: relative;
        .container {
            padding: 0; }
        &-in {
            position: relative;
            z-index: 2; }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 15px;
            width: 100vw;
            height: 100%;
            background: #fff;
            display: block;
            z-index: 1; } }
    &-ttl {
        padding: 42px 0;
        font-weight: 500;
        font-size: 36px;
        line-height: 110%; }
    &-main {
        &-txt {
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            padding: 43px 0;
            padding-right: 35px;
            position: relative;
            min-height: 100%;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 15px;
                width: 100vw;
                height: 100%;
                background: #F2F4F6;
                display: block;
                z-index: 1; }
            & > * {
                position: relative;
                z-index: 2; }
            &.content-box {
                ul {
                    font-size: 16px;
                    li {
                        margin-bottom: 10px;
                        &:before {
                            background: #323232; } } } } }

        &-left {
            padding: 0 15px;
            width: 100%;
            max-width: 50%; }
        &-right {
            padding: 0 15px;
            width: 100%;
            max-width: 50%; } }
    &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 20px 0;
        &-slider {
            width: 331px;
            height: 223px;
            padding: 0 40px;
            img {
                max-height: 223px; }
            .slick-arrow {
                position: absolute;
                top: 50%;
                margin-top: -11px;
                &.arrow-prev {
                    left: 0; }
                &.arrow-next {
                    right: 0; } } } }
    &-btns {
        background: #D8E9F3;
        &-left {
            padding: 50px 15px;
            position: relative;
            width: 100%;
            max-width: 50%;
            text-align: center;
            .btn {
                position: relative;
                z-index: 2; }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 30px;
                width: 100vw;
                height: 100%;
                display: block;
                z-index: 1;
                background: #065888; } }
        &-right {
            padding: 50px 15px;
            width: 100%;
            max-width: 50%;
            text-align: center;
            .btn {
                position: relative;
                z-index: 2; } } }
    &-txt {
        padding: 43px 35px;
        padding-left: 0;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 100vw;
            right: 0;
            height: 100%;
            display: block;
            z-index: 1;
            background: #F2F4F6; }
        & > * {
            position: relative;
            z-index: 1; } }
    &-adv {
        padding: 50px 0 28px;
        &-bl {
            width: 25%;
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 50px;
            &-img {
                margin-bottom: 19px; }
            &-txt {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                text-align: center; } } }
    &-tech {
        position: relative;
        padding: 42px 0;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 100vw;
            right: 0;
            height: 100%;
            display: block;
            z-index: 1;
            background: #F2F4F6; }
        & > * {
            position: relative;
            z-index: 2; }
        &-ttl {
            margin-bottom: 43px; }
        &-tbl {
            padding-right: 50px;
            .woocommerce-product-attributes.shop_attributes {
                border: 0; }
            .shop_attributes th, .shop_attributes td {
                width: 50%!important;
                padding: 17px 24px!important;
                font-weight: 400!important;
                font-style: normal!important;
                background: #fff!important;
                border: 0!important;
                border-bottom: 2px solid #f2f4f6!important; }
            .shop_attributes th:first-child, .shop_attributes td:first-child {
                text-align: left; }
            .shop_attributes th:last-child, .shop_attributes td:last-child {
                text-align: right;
                color: #065888; } } }
    &-list {
        &-ttl {
            margin-bottom: 43px;
            margin-top: 30px;
            position: relative;
            z-index: 3; } } }

/*single_prod*/

/*page_news*/
.page_news {
    &-wrap {
        padding: 50px 0 0px; }
    &-top {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        padding-bottom: 30px;
        &-col {
            padding: 0 10px;
            margin-bottom: 20px; }
        &-cat {
            background: #E2E4E6;
            border-radius: 20px;
            padding: 17px 24px;
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            color: #323232;
            display: block;
            &.current {
                background: #065888;
                color: #fff; } } }
    &-list {
        &-row {
            margin: 0 -15px; }
        &-bl {
            width: 100%;
            max-width: 405px;
            display: flex;
            flex-direction: column;
            height: 100%;
            &-img {
                display: block;
                text-align: center;
                margin-bottom: 31px; }
            &-cat {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #065888;
                margin-bottom: 20px; }
            &-ttl {
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: #323232;
                margin-bottom: 26px;
                display: block; }
            &-txt {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                color: #323232;
                margin-bottom: 20px;
                flex: 1; }
            &-date {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                color: $c_TorchRed; } }
        &-col {
            padding: 0 15px;
            max-width: 456px;
            width: 100%;
            padding-bottom: 63px; } }
    &-pagi {
        background: #F2F4F6;
        padding: 54px 0 34px;
        width: 100%;
        .wp-pagenavi {
            display: flex;
            flex-wrap: wrap;
            span, a {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #B0B1B3;
                border: 0;
                padding: 0;
                margin: 0 25px 20px; }
            & > * {
                &:first-child {
                    margin-left: 0; }
                &:last-child {
                    margin-right: 0; } }
            span {
                color: #065888; } } } }

/*page_news*/

/*single_news*/
.single_news {
    background: #f2f4f6;
    &-wrap {
        overflow: hidden; }
    &-right {
        width: 100%;
        max-width: 365px;
        padding: 0 15px;
        &_wrap {
            padding-left: 50px;
            padding-top: 50px; }
        .sidebar {
            padding: 43px 0; }
        &-back {
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #065888;
            display: inline-block;
            margin-bottom: 43px;
            svg {
                margin-right: 15px; } } }

    &-left {
        width: 100%;
        max-width: 1005px;
        padding: 0 15px;
        .container {
            padding: 0; } }
    &-left_wrap {
        position: relative;
        padding: 43px 0px 41px;
        margin-bottom: 2px;
        padding-right: 50px;
        &-row {
            position: relative;
            z-index: 2;
            display: flex;
            flex-wrap: wrap;
            padding: 1px 0; }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100vw;
            height: 100%;
            background: #fff;
            display: block; } }
    &-date {
        position: relative;
        z-index: 2;
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        margin-bottom: 10px;
        color: $c_TorchRed; }
    &-ttl {
        position: relative;
        z-index: 2;
        font-weight: 500;
        margin-bottom: 20px; }
    &-exerpt {
        z-index: 2;
        position: relative;
        margin-bottom: 43px;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #065888; }
    &-img {
        margin-bottom: 39px;
        position: relative;
        z-index: 2; }
    &-content {
        position: relative;
        z-index: 2; } }
/*single_news*/

/*page_partn*/
.page_partn {
    &-advan {
        overflow: hidden;
        &-left {
            padding: 0 15px;
            width: 100%;
            max-width: 50%;
            position: relative;
            &:before {
                content: '';
                display: block;
                width: 720px;
                height: 527px;
                background: no-repeat 0% 0%;
                background-image: url('../img/partn-page.jpg');
                background-size: cover;
                left: -35px;
                position: absolute;
                top: 0;
                z-index: 1; }
            &-bl {
                position: relative;
                z-index: 2;
                display: flex;
                flex-direction: column;
                padding: 41px 0;
                padding-right: 15px;
                height: 527px; } }

        &-right {
            width: 100%;
            max-width: 50%;
            padding: 0 15px; }
        &-content {
            margin-bottom: 43px;
            &.content-box {
                & > * {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 155%; }
                ul {
                    li {
                        margin-bottom: 3px;
                        &:before {
                            background: #B0B1B3; } } } } }
        &-bl {
            padding-left: 35px;
            padding-bottom: 50px;
            padding-top: 50px; }
        &-ttl {
            color: #fff;
            font-weight: 500;
            font-size: 36px;
            line-height: 110%;
            padding-bottom: 30px;
            flex: 1; }
        &-txt {
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            color: #fff; } }
    &-steps {
        overflow: hidden;
        background: #F2F4F6;
        &-bl {
            padding: 41px 0;
            max-width: 620px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%; }
        &-left {
            width: 100%;
            max-width: 50%;
            padding: 0 15px; }
        &-ttl {
            font-weight: 500;
            font-size: 36px;
            line-height: 110%;
            color: #065888;
            margin-bottom: 39px; }
        &-txt {
            &.content-box {
                & > * {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 140%; }
                ol {
                    li {
                        margin-bottom: 3px;
                        &:before {
                            background: #B0B1B3; } } } } }
        &-right {
            padding: 0 15px;
            width: 100%;
            max-width: 50%;
            position: relative;
            &:before {
                content: '';
                display: block;
                width: 100vw;
                height: 100%;
                background: $c_VeniceBlue;
                left: 0px;
                position: absolute;
                top: 0;
                z-index: 1; } }
        &-form {
            &_wrap {
                position: relative;
                z-index: 2;
                padding: 50px 0;
                padding-left: 35px; }
            &-ttl {
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: $c_White;
                margin-bottom: 44px; } } } }
/*page_partn*/

/*page_cont*/
.page_cont {
    &-data {
        overflow: hidden; }
    &-map {
        width: 720px;
        height: 540px; }
    &-left {
        padding: 0 15px;
        width: 100%;
        max-width: 50%; }
    &-right {
        padding: 0 15px;
        width: 100%;
        max-width: 50%;
        position: relative;
        height: 540px; }
    &-map {
        position: absolute;
        left: 0;
        background: #f2f4f6; }
    &-bl {
        display: flex;
        flex-direction: column;
        padding: 50px 0;
        padding-right: 35px;
        justify-content: center;
        height: 100%;
        &-ttl {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            margin-bottom: 18px; }
        &-phn {
            margin-bottom: 10px;
            a {
                font-weight: 500;
                font-size: 36px;
                line-height: 110%;
                color: $c_TorchRed;
                &:hover {
                    color: $c_VeniceBlue; } } }
        &-ml {
            margin-bottom: 25px;
            a {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                color: $c_VeniceBlue;
                &:hover {
                    color: $c_TorchRed; } } }
        &-adr {
            &.content-box {
                p {
                    margin-bottom: 0; } } } }
    &-container {
        &:nth-child(2n) {
            .row {
                flex-direction: row-reverse; }
            .page_cont {
                &-map {
                    left: auto;
                    right: 0; }
                &-bl {
                    padding-right: 0;
                    padding-left: 35px; } } } }
    &-form {
        background: $c_VeniceBlue;
        padding: 50px 0;
        &-ttl {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: $c_White;
            margin-bottom: 44px; }
        .form-cont {
            &-col {
                padding: 0 15px;
                width: 100%;
                max-width: 50%; } } } }

/*page_cont*/

/*page_tech*/
.page_tech {
    &-info {
        background: #EAECED;
        &-left {
            width: 100%;
            max-width: 50%;
            padding: 50px 15px;
            position: relative;
            &:before {
                content: '';
                display: block;
                position: absolute;
                height: 100%;
                width: 720px;
                top: 0;
                right: 0;
                background: #065888; } }
        &-right {
            width: 100%;
            max-width: 50%;
            padding: 50px 15px; }
        &-form {
            position: relative;
            z-index: 2;
            .form.form-partn {
                max-width: 620px; } }
        &-bl {
            display: flex;
            max-width: 620px;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding-left: 35px; }
        &-txt {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            margin-bottom: 18px; }
        &-phn {
            margin-bottom: 12px;
            a {
                font-weight: 500;
                font-size: 36px;
                line-height: 110%;
                color: #F90936; } }
        &-ml {
            a {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                text-decoration-line: underline;
                color: #065888; } } } }

/*page_tech*/

.footer {
    padding: 49px 0;
    border-top: 2px solid #f2f4f6; }

.f {
    &-logo {
        padding: 0 15px;
        width: 49%; }
    &-cont {
        padding: 0 15px;
        width: 100%;
        max-width: 49%; }
    &-cont {
        &-top {
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #B0B1B3;
            margin-bottom: 26px; }
        &-phn {
            margin-bottom: 14px;
            a {
                font-weight: 500;
                font-size: 36px;
                line-height: 110%;
                color: #F90936; } }
        &-ml {
            margin-bottom: 36px;
            a {
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                text-decoration-line: underline;
                color: #065888; } } }
    &-copy {
        max-width: 49%;
        padding: 0 15px;
        width: 100%; }
    &-btn {
        max-width: 49%;
        padding: 0 15px;
        width: 100%; } }

.sidebar_prod-wrap {
    padding-bottom: 30px; }
