/*header*/


@media(min-width: 1200px) {
    .h-mob {
        display: none !important; } }

@media(max-width: 1199px) {
    .h-mob {
        top: 120px;
        position: absolute;
        width: 100%;
        background: #fff;
        min-height: calc(100vh - 120px);
        padding: 40px 0;
        .h-soc {
            svg {
                path {
                    fill: #b0b1b2; } } }
        .h-menu {
            ul {
                justify-content: flex-start;
                li {
                    a {
                        color: #000; } } } }
        .h-auth {
            a {
                background: url('../img/auth_white.svg')no-repeat 0 50%;
                color: #000; } }
        .h-phn {
            a {
                color: #000; } } }
    .h {
        &-menu {
            ul {
                li.menu-item-has-children {
                    &:before {
                        border-color: #065888; } } } } } }

@media(max-width: 1479px) and (min-width: 1200px) {
    .h {
        &-menu {
            ul {
                margin: 0 -11px;
                li {
                    padding: 0 5px 15px;
                    a {
                        font-size: 14px; } } } } } }

@media (max-width: 1199px) and (min-width: 960px) {
    .h {
        &-menu {
            display: none; }
        &-info {
            flex-direction: column; }
        &-auth {
            padding-top: 18px; }
        &-btn {
            display: block; }
        &-auth {
            a {
                font-size: 16px; } }
        &-phn {
            margin-top: 0;
            a {
                font-size: 16px; } } }

    .h-mob {
        .h {
            &-menu {
                display: block; }
            &-soc {
                display: none; }
            &-auth {
                display: none; }
            &-phn {
                display: none; } } } }

@media (max-width: 959px) and (min-width: 640px) {
    .h {
        &-menu {
            display: none; }
        &-info {
            display: none; }
        &-auth {
            padding-top: 18px; }
        &-btn {
            display: block; }
        &-auth {
            a {
                font-size: 16px; } }
        &-phn {
            margin-top: 0;
            a {
                font-size: 16px; } } }

    .h-mob {
        top: 106px;
        min-height: calc(100vh - 106px);
        .h {
            &-menu {
                display: block; }
            &-soc {
                display: block; }
            &-auth {
                display: none; }
            &-phn {
                display: block; } } } }

@media (max-width: 639px) {
    .h {
        &-logo {
            margin-top: 0;
            padding: 30px 15px;
            svg {
                path[fill="white"] {
                    fill: $c_Black; } } }
        &-menu {
            display: none; }
        &-cont {
            padding: 30px 0;
            justify-content: flex-end; }
        &-info {
            display: none; }
        &-auth {
            display: none; }
        &-btn {
            display: block;
            padding: 0 15px; }
        &-auth {
            a {
                font-size: 16px; } }
        &-phn {
            margin-top: 0;
            a {
                font-size: 16px; } } }

    .header {
        background: $c_White;
        &.static {
            background: $c_White; } }

    .h-mob {
        top: 125px;
        min-height: calc(100vh - 125px);
        .h {
            &-menu {
                display: block;
                ul {
                    flex-direction: column;
                    align-items: flex-start; }
                .sub-menu {
                    position: static;
                    display: none; }
                li.menu-item-has-children.hovered {
                    .sub-menu {
                        display: block; } } }
            &-soc {
                display: block; }
            &-auth {
                display: block; }
            &-phn {
                display: block; } } } }
/*header*/


@media (max-width: 639px) {
    .video-back {
        display: none; } }

/*main page*/


@media(min-width: 1200px) {
    .main {
        &-first {
            min-height: 900px; } } }

@media(max-width: 1479px) and (min-width: 1200px) {
    .main {
        &-numbers {
            &-left {
                max-width: 720px; }
            &-bl {
                &-ttl {
                    font-size: 36px; }
                &-txt {
                    font-size: 14px; } }
            &-col {
                max-width: 235px; } } } }

@media(min-width: 1480px) {
    .main {
        &-hot {
            .container {
                max-width: 1470px; }
            &-slide {
                &-left {
                    padding-left: 50px; } } } } }

@media (max-width: 1199px) and (min-width: 960px) {
    .main {
        &-first {
            min-height: 652px;
            &-ttl {
                font-size: 36px;
                line-height: 110%; } }
        &-numbers {
            &-left {
                max-width: 603px; }
            &-bl {
                &-ttl {
                    font-size: 45px;
                    line-height: 57px; }
                &-txt {
                    font-size: 18px;
                    line-height: 140%; } }
            &-col {
                max-width: 286px; }
            &-one {
                &-txt {
                    font-size: 17px; } } }
        &-hot {
            &-slide {
                &-left {
                    max-width: 380px; } }
            &-slider {
                .slick-arrow {
                    &.arrow-prev {
                        left: 310px; }
                    &.arrow-next {
                        left: 350px; } } } }
        &-revs {
            &-left {
                max-width: none;
                &:before {
                    left: -50%;
                    width: calc(100% + 100%); } }
            &-right {
                flex: unset;
                width: 100%;
                max-width: none;
                &-top {
                    position: relative;
                    overflow: visible;
                    &:before {
                        content: '';
                        background: $c_White;
                        z-index: 1;
                        position: absolute;
                        left: -50%;
                        top: 0;
                        width: calc(100% + 100%);
                        height: 100%;
                        display: block; } }
                &-btm {
                    padding: 46px 0px; } }
            &-slider {
                position: relative;
                z-index: 2; }
            &-slide {
                padding-left: 0; } } } }

@media (max-width: 959px) and (min-width: 640px) {
    .main {
        &-first {
            min-height: 667px;
            &-ttl {
                font-size: 36px;
                line-height: 110%; }
            &-btn {
                margin-bottom: 193px; } }
        &-numbers {
            &-col {
                max-width: 50%; }
            &-bl {
                &-ttl {
                    font-size: 45px;
                    line-height: 57px; } }
            &-one {
                height: 357px;
                padding: 35px 0 43px;
                &:before {
                    width: 899px;
                    left: -160px; } } }
        &-hot {
            &-slider {
                margin-left: -15px;
                width: calc(100% + 30px); }
            &-slide {
                &-left {
                    max-width: none; }
                &-right {
                    flex: unset; }
                &-txt {
                    margin-bottom: 160px; } }
            &-slider {
                .slick-arrow {
                    &.arrow-next {
                        left: auto;
                        right: 15px; }
                    &.arrow-prev {
                        left: auto;
                        right: 80px; } } } }
        &-revs {
            &-left {
                max-width: none;
                &:before {
                    left: -50%;
                    width: calc(100% + 100%); } }
            &-right {
                flex: unset;
                width: 100%;
                max-width: none;
                &-top {
                    position: relative;
                    overflow: visible;
                    &:before {
                        content: '';
                        background: $c_White;
                        z-index: 1;
                        position: absolute;
                        left: -50%;
                        top: 0;
                        width: calc(100% + 100%);
                        height: 100%;
                        display: block; } }
                &-btm {
                    padding: 46px 0px; } }
            &-slider {
                position: relative;
                z-index: 2; }
            &-slide {
                padding-left: 0; }
            &-slider_text {
                &.slick-arrow {
                    &.arrow-prev {
                        left: auto;
                        right: 55px; }
                    &.arrow-next {
                        right: 0; } } } }
        &-news {
            &-col {
                width: 100%;
                &:first-child {
                    .main-news-bl {
                        border-bottom: 2px solid #e2e4e6;
                        border-right: 0; } } }

            &-ttl {
                width: 100%;
                flex: unset;
                margin-bottom: 16px; }
            &-more {
                margin-bottom: 38px; } } } }

@media (max-width: 639px) {
    .main {
        &-first {
            background: $c_VeniceBlue!important;
            &:before {
                display: none; }
            &-ttl {
                font-size: 24px;
                line-height: 120%; } }
        &-numbers {
            &-bl {
                &-ttl {
                    font-size: 45px;
                    line-height: 57px; }

                &-txt {
                    font-size: 20px;
                    line-height: 140%; } }
            &-one {
                height: 357px;
                padding: 35px 0 43px;
                &:before {
                    width: 639px;
                    left: -30px; } } }
        &-hot {
            &-slider {
                margin-left: -30px;
                width: calc(100% + 60px); }
            &-slide {
                &-left {
                    max-width: none;
                    padding: 46px 30px; }
                &-right {
                    flex: unset; }
                &-top {
                    margin-top: 54px; }
                &-txt {
                    margin-bottom: 160px; } }
            &-slider {
                .slick-arrow {
                    &.arrow-next {
                        left:  85px; }
                    &.arrow-prev {
                        left: 30px; } } } }
        &-revs {
            &-left {
                max-width: none;
                &:before {
                    left: -50%;
                    width: calc(100% + 100%); } }
            &-right {
                flex: unset;
                width: 100%;
                max-width: none;
                padding: 0 15px;
                &-top {
                    position: relative;
                    overflow: visible;
                    &:before {
                        content: '';
                        background: $c_White;
                        z-index: 1;
                        position: absolute;
                        left: -50%;
                        top: 0;
                        width: calc(100% + 100%);
                        height: 100%;
                        display: block; } }
                &-btm {
                    padding: 46px 0px; } }
            &-slider {
                position: relative;
                z-index: 2; }
            &-slide {
                padding-left: 0; }
            &-slider_text {
                .slick-arrow {
                    &.arrow-prev {
                        left: 0;
                        right: auto; }
                    &.arrow-next {
                        left: 55px;
                        right: auto; } } }
            &-slide_text {
                &-top {
                    margin-top: 54px; }
                &-txt {
                    font-size: 20px;
                    line-height: 140%; } } }
        &-news {
            &-col {
                width: 100%;
                &:first-child {
                    .main-news-bl {
                        border-bottom: 2px solid #e2e4e6;
                        border-right: 0; } } }
            &-bl {
                padding: 25px; }
            &-ttl {
                width: 100%;
                flex: unset;
                margin-bottom: 16px; }
            &-more {
                margin-bottom: 38px; } } } }


/*main page*/

/*page about*/
@media(min-width: 1200px) {
    .page_about {
        &-first {
            min-height: 900px; } } }

@media(max-width: 1479px) and (min-width: 1200px) {
    .page_about {
        &-hist {
            &-left, &-right {
                max-width: 585px; } }
        &-info {
            &-right {
                max-width: 500px; } }
        &-empl {
            &-bl {
                max-width: 288px; } }
        &-sert {
            &-left {
                max-width: 805px; } }
        &-partn {
            &-right {
                max-width: 670px; }
            &-left {
                max-width: 500px; } }
        &-team {
            &-left {
                max-width: 500px; }
            &-img {
                &:before {
                    left: auto;
                    right: -30px; } }
            &-right {
                max-width: 670px; }
            &-bl {
                padding-left: 40px; } } } }

@media (max-width: 1199px) and (min-width: 960px) {
    .page_about {
        &-first {
            min-height: 652px;
            &-ttl {
                font-size: 36px;
                line-height: 110%; } }
        &-hist {
            &-cont {
                &-ttl {
                    font-size: 24px;
                    line-height: 120%;
                    margin-bottom: 34px; } }
            &-right {
                max-width: none; } }
        &-dates {
            &-slider_wrap {
                overflow: hidden; } }
        &-info {
            &-left {
                order: 2;
                max-width: none; }
            &-right {
                order: 1;
                max-width: none; }
            &-bl {
                padding: 42px 0px;
                &:before {
                    left: -50px;
                    width: 960px; } }
            &-directors {
                max-width: none;
                width: 100%;
                &-bl {
                    width: 50%; } } }
        &-empl {
            &-bl {
                max-width: 50%; } }
        &-sert {
            &-right {
                order: 1;
                max-width: none;
                &-bl {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    &:before {
                        width: 100%;
                        top: 0;
                        display: block;
                        left: -90px;
                        height: 100%;
                        width: calc(100% + 160px);
                        background: $c_VeniceBlue; } } }
            &-left {
                order: 2; } }
        &-partn {
            &-left {
                max-width: none; }
            &-right {
                max-width: none; }
            &-btn {
                padding: 70px 0;
                &:before {
                    background: #d8e9f3;
                    z-index: 1;
                    left: -50%;
                    width: calc(100% + 100%);
                    height: 100%; } } }
        &-team {
            &-left {
                max-width: 50%; }
            &-img {
                &:before {
                    left: auto;
                    right: 0; } }
            &-right {
                max-width: 50%; } } } }

@media (max-width: 959px) and (min-width: 640px) {
    .page_about {
        &-first {
            min-height: 667px;
            &-ttl {
                font-size: 36px;
                line-height: 110%; }
            &-btn {
                margin-bottom: 193px; } }
        &-hist {
            &-cont {
                &-ttl {
                    font-size: 24px;
                    line-height: 120%;
                    margin-bottom: 34px; } }
            &-right {
                max-width: none; } }
        &-dates {
            &-slider_wrap {
                overflow: hidden; } }
        &-info {
            &-left {
                order: 2;
                max-width: none; }
            &-right {
                order: 1;
                max-width: none; }
            &-bl {
                padding: 42px 0px;
                &:before {
                    left: -50px;
                    width: 960px; } }
            &-directors {
                max-width: none;
                width: 100%;
                &-bl {
                    width: 50%; } } }
        &-empl {
            &-bl {
                max-width: 50%; } }
        &-sert {
            &-bl {
                &-img {
                    img {
                        margin: 0 auto; } } }
            &-right {
                order: 1;
                max-width: none;
                &-bl {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    &:before {
                        width: 100%;
                        top: 0;
                        display: block;
                        left: -90px;
                        height: 100%;
                        width: calc(100% + 160px);
                        background: $c_VeniceBlue; } } }
            &-left {
                order: 2; } }
        &-partn {
            &-left {
                max-width: none; }
            &-right {
                max-width: none; }
            &-btn {
                padding: 70px 0;
                &:before {
                    background: #d8e9f3;
                    z-index: 1;
                    left: -50%;
                    width: calc(100% + 100%);
                    height: 100%; } } }
        &-team {
            &-img {
                &:before {
                    position: static;
                    width: 640px;
                    height: 427px;
                    margin-left: -30px; } }
            &-txt {
                flex: unset;
                margin-bottom: 44px; }
            &-bl {
                padding-left: 0;
                height: auto; } } } }

@media (max-width: 639px) {
    .page_about {
        &-first {
            background: $c_VeniceBlue!important;
            &:before {
                display: none; }
            &-ttl {
                font-size: 24px;
                line-height: 120%; } }
        &-hist {
            &-cont {
                &-ttl {
                    font-size: 24px;
                    line-height: 120%;
                    margin-bottom: 34px; } }
            &-right {
                max-width: none; } }
        &-dates {
            &-slider_wrap {
                overflow: hidden; } }
        &-info {
            &-left {
                order: 2;
                max-width: none; }
            &-right {
                order: 1;
                max-width: none; }
            &-bl {
                padding: 42px 0px;
                min-height: 1px;
                &:before {
                    left: -50px;
                    width: 960px; }
                &-ttl {
                    font-size: 24px;
                    line-height: 120%; }
                &-txt {
                    font-size: 16px;
                    line-height: 155%; } }
            &-directors {
                &-bl {
                    max-width: 220px;
                    width: 100%; } } }
        &-empl {
            .row {
                justify-content: space-between; }
            &-bl {
                max-width: 220px;
                width: 100%; } }
        &-sert {
            &-ttl {
                width: 100%;
                margin-bottom: 53px; }
            &-bl {
                &-img {
                    img {
                        margin: 0 auto; } } }
            &-right {
                order: 1;
                max-width: none;
                &-bl {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    padding-left: 0;
                    flex-wrap: wrap;
                    &:before {
                        width: 100%;
                        top: 0;
                        display: block;
                        left: -90px;
                        height: 100%;
                        width: calc(100% + 160px);
                        background: $c_VeniceBlue; } } }
            &-left {
                order: 2; } }
        &-partn {
            &-left {
                max-width: none; }
            &-right {
                max-width: none; }
            &-btn {
                padding: 70px 0;
                &:before {
                    background: #d8e9f3;
                    z-index: 1;
                    left: -50%;
                    width: calc(100% + 100%);
                    height: 100%; } } }
        &-team {
            &-img {
                &:before {
                    position: static;
                    width: 640px;
                    height: 427px;
                    margin-left: -30px; } }
            &-txt {
                flex: unset; }
            &-bl {
                padding-left: 0;
                height: auto; } } } }
/*page about*/

/*page serv*/
@media (max-width: 959px) {
    .page_serv {
        &-row {
            &-left {
                max-width: none; }
            &-right {
                max-width: none;
                padding-left: 0;
                padding-top: 44px; }
            &-img {
                height: 427px;
                margin: 0;
                margin-left: -30px;
                width: calc(100% + 60px); } } } }

@media (max-width: 639px) {
    .page_serv {
        &-row {
            &-img {
                background: #fff!important;
                height: 189px; }
            &-num {
                color: #F90936; } } } }
/*page serv*/

/*page_w_sys*/
@media(max-width: 1479px) and (min-width: 1200px) {
    .page_w_sys {
        &-numbers {
            &-col {
                max-width: 290px; }
            &-bl {
                &-txt {
                    font-size: 18px; } } }
        &-team {
            &-left {
                max-width: 500px; }
            &-img {
                &:before {
                    left: auto;
                    right: -30px; } }
            &-right {
                max-width: 670px; }
            &-bl {
                padding-left: 40px; } }
        &-sph {
            &-bl {
                height: 290px;
                padding: 41px 30px; } }
        &-why {
            &-col {
                max-width: 390px; } } } }

@media (max-width: 1199px) and (min-width: 960px) {
    .page_w_sys {
        &-numbers {
            &-col {
                max-width: 50%; } }

        &-team {
            &-left {
                max-width: none; }
            &-img {
                height: 640px;
                &:before {
                    left: -50px;
                    width: calc(100% + 100px);
                    background-repeat: no-repeat;
                    height: 640px;
                    background-position: 50% 50%;
                    background-size: cover; } }
            &-right {
                max-width: none; }

            &-txt {
                width: 100%;
                flex: unset;
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    li {
                        width: 50%; } } }
            &-bl {
                min-height: 1px;
                padding-left: 0; } }
        &-sph {
            &-col {
                max-width: 50%;
                &.big {
                    max-width: 100%; } } }
        &-prod {
            &-col {
                max-width: none;
                &:first-child {
                    border-right: 0;
                    border-bottom: 1px solid $c_White; }
                &:last-child {
                    padding-left: 15px; } }
            &-bl {
                &-txt {
                    max-width: none;
                    margin-bottom: 43px; } } }
        &-info {
            &-left {
                max-width: none; }
            &-ttl {
                max-width: none; }
            &-right {
                max-width: none; } }
        &-cats {
            &-col {
                max-width: 50%; } }
        &-why {
            &-col {
                max-width: 50%; } } } }


@media (max-width: 959px) and (min-width: 640px) {
    .page_w_sys {
        &-numbers {
            &-col {
                max-width: 50%; } }
        &-team {
            &-left {
                max-width: none; }
            &-img {
                height: 640px;
                &:before {
                    left: -30px;
                    width: calc(100% + 60px);
                    background-repeat: no-repeat;
                    height: 427px;
                    background-position: 50% 50%;
                    background-size: cover; } }
            &-right {
                max-width: none; }

            &-txt {
                width: 100%;
                flex: unset; }
            &-bl {
                min-height: 1px;
                padding-left: 0; } }
        &-sph {
            &-col {
                max-width: 100%;
                &.big {
                    max-width: 100%; } } }
        &-prod {
            &-col {
                max-width: none;
                &:first-child {
                    border-right: 0;
                    border-bottom: 1px solid $c_White; }
                &:last-child {
                    padding-left: 15px; } }
            &-bl {
                &-txt {
                    max-width: none;
                    margin-bottom: 43px; } } }
        &-info {
            &-left {
                max-width: none; }
            &-ttl {
                max-width: none; }
            &-right {
                max-width: none; } }
        &-cats {
            &-col {
                max-width: 50%; } }
        &-why {
            &-ttl {
                flex: unset;
                width: 100%; }
            &-col {
                max-width: 100%; } } } }

@media (max-width: 639px) {
    .page_w_sys {
        &-numbers {
            &-col {
                max-width: 100%; } }
        &-team {
            &-left {
                max-width: none; }
            &-img {
                display: none; }
            &-right {
                max-width: none; }

            &-txt {
                width: 100%;
                flex: unset; }
            &-bl {
                min-height: 1px;
                padding-left: 0; } }
        &-sph {
            padding-bottom: 0;
            &-col {
                border: 0;
                border-bottom: 1px solid $c_White;
                margin: 0 -30px;
                width: calc(100% + 60px);
                max-width: none;
                &.big {
                    max-width: none; } }
            &-bl {
                padding: 41px 30px; } }
        &-prod {
            &-col {
                max-width: none;
                &:first-child {
                    border-right: 0;
                    border-bottom: 1px solid $c_White; }
                &:last-child {
                    padding-left: 15px; } }
            &-bl {
                &-txt {
                    max-width: none;
                    margin-bottom: 43px; } } }
        &-info {
            &-left {
                max-width: none; }
            &-ttl {
                max-width: none; }
            &-right {
                max-width: none; } }
        &-cats {
            &-col {
                max-width: 100%; } }
        &-why {
            &-ttl {
                flex: unset;
                width: 100%; }
            &-col {
                max-width: 100%; } } } }
/*page_w_sys*/

/*page_w_host*/
@media (max-width: 1199px) and (min-width: 960px) {
    .page_w_host {
        &-sph {
            &-col {
                max-width: 50%; } }
        &-doc {
            &-left {
                max-width: none; }
            &-txt {
                max-width: none; }
            &-bl {
                &:before {
                    left: -50%;
                    width: calc(100vw + 100%); } }
            &-right {
                max-width: none; } } } }

@media (max-width: 959px) and (min-width: 640px) {
    .page_w_host {
        &-adv {
            &-bl {
                &-txt {
                    ul {
                        li {
                            width: 100%; } } } } }
        &-sph {
            &-col {
                max-width: 100%; } }
        &-doc {
            &-left {
                max-width: none; }
            &-txt {
                max-width: none; }
            &-bl {
                &:before {
                    left: -50%;
                    width: calc(100vw + 100%); } }
            &-right {
                max-width: none; } } } }

@media (max-width: 639px) {
    .page_w_host {
        &-adv {
            &-bl {
                padding: 46px 30px;
                margin: 0 -30px;
                &-txt {
                    ul {
                        li {
                            width: 100%; } } } } }
        &-sph {
            padding-bottom: 0;
            &-col {
                border: 0;
                border-bottom: 1px solid $c_White;
                margin: 0 -30px;
                width: calc(100% + 60px);
                max-width: none;
                &.big {
                    max-width: none; } }
            &-bl {
                padding: 41px 30px; } }
        &-doc {
            &-left {
                max-width: none; }
            &-txt {
                max-width: none; }
            &-bl {
                &:before {
                    left: -50%;
                    width: calc(100vw + 100%); } }
            &-right {
                max-width: none; } } } }
/*page_w_host*/

/*page_w_host*/
@media(max-width: 1479px) and (min-width: 1200px) {
    .page_w_mob {
        &-app {
            &-left {
                max-width: 600px; }
            &-right {
                max-width: 570px; } } } }

@media (max-width: 1199px) and (min-width: 960px) {
    .page_w_mob {
        &-app {
            &-img {
                height: auto;
                padding-bottom: 50px;
                align-items: flex-start;
                .sm {
                    display: block; }
                .lg {
                    display: none; } }
            &-left {
                max-width: 460px; }
            &-right {
                max-width: 430px; }
            &-bl {
                padding-right: 34px; }
            &-btns {
                a {
                    &:last-child {
                        margin-right: 0; } } } } } }

@media (max-width: 959px) and (min-width: 640px) {
    .page_w_mob {
        &-app {
            &-left {
                max-width: 100%; }
            &-right {
                display: none; }
            &-bl {
                padding-right: 34px; } } } }

@media (max-width: 639px) {
    .page_w_mob {
        &-app {
            &-left {
                max-width: 100%; }
            &-right {
                display: none; }
            &-bl {
                padding: 41px 30px 50px;
                margin: 0 -30px; }
            &-btns {
                a {
                    margin-bottom: 25px;
                    margin-right: 0 !important; } } } } }

/*page_w_host*/

/*page_decis*/
@media (max-width: 959px) and (min-width: 640px) {
    .page_decis {
        &-col {
            max-width: 100%; } } }

@media (max-width: 639px) {
    .page_decis {
        &-col {
            border: 0;
            border-bottom: 1px solid #F2F4F6;
            margin: 0 -30px;
            width: calc(100% + 60px);
            max-width: none;
            &.big {
                max-width: none; } }
        &-bl {
            padding: 41px 30px; } } }
/*page_decis*/

/*page_decis_sing*/
@media(min-width: 1200px) {
    .page_decis_sing {
        .demo-section-bl:before {
            width: 490px; } } }
@media(max-width: 1479px) and (min-width: 1200px) {
    .page_decis_sing {
        &-left {
            max-width: 900px; }
        &-right {
            max-width: 270px;
            &_wrap {
                padding-left: 0; } } } }
@media(max-width: 1199px) and (min-width: 960px) {
    .page_decis_sing {
        &-left {
            order: 2; }
        &-right {
            order: 1;
            max-width: none;
            &_wrap {
                padding-left: 0;
                padding-top: 42px; } }

        &-why {
            &:before {
                left: -50%; }
            .page_w_sys-why-col {
                max-width: 50%; } }

        &-wrap {
            overflow: hidden;
            max-width: 100%; }
        &-func {
            &:before {
                left: -50%; } }
        .page_w_host-doc-bl:before {
            width: calc(100vw + 100%); }
        .page_w_host-doc:before {
            left: -50%; }
        &-app {
            &:before {
                left: -50%;
                width: calc(100% + 100%); } } } }

@media(max-width: 959px) and (min-width: 640px) {
    .page_decis_sing {
        &-left {
            order: 2; }
        &-right {
            order: 1;
            max-width: none;
            &_wrap {
                padding-left: 0;
                padding-top: 42px; } }

        &-why {
            &:before {
                left: -50%; } }

        &-wrap {
            overflow: hidden;
            max-width: 100%; }
        &-func {
            &:before {
                left: -50%; } }
        .page_w_host-doc-bl:before {
            width: calc(100vw + 100%); }
        .page_w_host-doc:before {
            left: -50%; }
        &-equip {
            &-col {
                max-width: none; } }
        &-app {
            &:before {
                left: -50%;
                width: calc(100% + 100%); } } } }

@media(max-width: 639px) {
    .page_decis_sing {
        .demo-section {
            margin: 0 -30px;
            padding: 0 30px; }
        &-left {
            order: 2; }
        &-right {
            order: 1;
            max-width: none;
            &_wrap {
                padding-left: 0;
                padding-top: 42px; } }

        &-why {
            &:before {
                left: -50%; } }

        &-wrap {
            overflow: hidden;
            max-width: 100%; }
        &-func {
            margin: 0 -30px;
            &-top {
                padding: 0 30px; }
            &:before {
                left: -50%; }
            &-slider {
                .slick-arrow {
                    &.arrow-prev {
                        left: 4px; }
                    &.arrow-next {
                        right: 4px; } } } }
        .page_w_host-doc-bl:before {
            width: calc(100vw + 100%); }
        .page_w_host-doc:before {
            left: -50%; }
        &-equip {
            &-col {
                max-width: none; } }
        &-app {
            &:before {
                left: -50%;
                width: calc(100% + 100%); }
            &-ttl {
                font-size: 26px; } } } }
/*page_decis_sing*/

/*page_equip*/
@media(max-width: 1199px) and (min-width: 960px) {
    .page_equip {
        &-col {
            max-width: 50%; } } }
@media(max-width: 959px) and (min-width: 640px) {
    .page_equip {
        &-col {
            max-width: 100%; } } }
@media(max-width: 639px) {
    .page_equip {
        &-row {
            margin: 0 -30px; }
        &-col {
            max-width: 100%;
            border-right: 0; } } }
/*page_equip*/

/*page_equip_cat*/
@media(max-width: 1479px) and (min-width: 1200px) {
    .page_equip_cat {
        &-left {
            max-width: 804px; }
        &-list {
            &-bl {
                &-buy, &-more {
                    a.btn-gray_bord {
                        span {
                            font-size: 12px; } } } } } } }
@media(min-width: 1200px) {
    .page_equip_cat {
        &-list {
            &-row {
                margin-right: -7px; } } } }
@media(max-width: 1199px) and (min-width: 960px) {
    .page_equip_cat {
        &-left {
            order: 2; }
        &-right {
            order: 1;
            max-width: none;
            &_wrap {
                padding-left: 0;
                padding-top: 42px; } }
        &-list {
            &:before {
                right: auto;
                left: -50%;
                width: calc(100vw + 100%); }
            &-bl {
                max-width: 50%; } } } }
@media(max-width: 959px) and (min-width: 640px) {
    .page_equip_cat {
        &-left {
            order: 2; }
        &-right {
            order: 1;
            max-width: none;
            &_wrap {
                padding-left: 0;
                padding-top: 42px; } }
        &-list {
            &:before {
                right: auto;
                left: -50%;
                width: calc(100vw + 100%); }
            &-bl {
                max-width: 100%; } } } }
@media(max-width: 639px) {
    .page_equip_cat {
        &-left {
            order: 2; }
        &-right {
            order: 1;
            max-width: none;
            &_wrap {
                padding-left: 0;
                padding-top: 42px; } }
        &-list {
            &:before {
                right: auto;
                left: -50%;
                width: calc(100vw + 100%); }
            &-row {
                margin: 0 -30px; }
            &-bl {
                max-width: 100%;
                border-right: 0; } } } }
/*page_equip_cat*/

/*single_prod*/
@media(max-width: 1199px) {
    .single_prod {
        &-left {
            max-width: none; }
        &-wrap {
            background: #fff; }
        .page_equip_cat-right {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                width: calc(100% + 100%);
                height: 100%;
                display: block;
                z-index: 1;
                background: #eaeced;
                left: -50%; }
            .sidebar {
                position: relative;
                z-index: 2; } }
        &-btns {
            &-right {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 100vw;
                    height: 100%;
                    display: block;
                    z-index: 1;
                    background: #d8e9f3; } } }
        &-txt {
            &:before {
                left: -50%;
                width: calc(100% + 100%); } }
        &-tech {
            &:before {
                left: -50%;
                width: calc(100% + 100%); } }
        &-main {
            &-txt {
                &:before {
                    right: -15px; } } }
        &-btns {
            &-left {
                &:before {
                    right: 0; } } } } }

@media(max-width: 959px) {
    .single_prod-main-left, .single_prod-main-right {
        max-width: none; }
    .single_prod-main-txt:before {
        left: -50%;
        width: calc(100% + 100%); }


    .single_prod-main-left {
        order: 2; }


    .single_prod-main-right {
        order: 1; }


    .single_prod-btns-left, .single_prod-btns-right {
        max-width: none; }


    .single_prod-btns-left:before {
        left: -50%;
        width: calc(100% + 100%); }


    .single_prod-btns-right:before {
        left: -50%;
        width: calc(100% + 100%); }


    .single_prod-adv-bl {
        width: 100%;
        max-width: 245px; }


    .single_prod-adv .row {
        justify-content: center; }
    .single_prod-tech-tbl {
        padding-right: 0; } }

@media(max-width: 639px) {
    .single_prod-tech-tbl {
        .shop_attributes td, .shop_attributes th {
            text-align: left!important;
            display: block;
            width: 100%!important; }
        tr {
            display: block; }
        table.woocommerce-product-attributes.shop_attributes {
            display: block; }
        tbody {
            display: block; }
        .shop_attributes td:first-child, .shop_attributes th:first-child {
            border-bottom: 0!important; } } }

/*single_prod*/

/*page_news*/
@media(min-width: 1200px) {
    .page_news {
        &-list {
            &-bl {
                &-ttl {
                    min-height: 84px; } } } } }

@media(max-width: 1199px) and (min-width: 960px) {
    .page_news {
        &-list {
            &-col {
                max-width: 445px; } } } }
@media(max-width: 959px) {
    .page_news {
        &-list {
            &-col {
                max-width: none; }
            &-bl {
                max-width: none; } } } }
/*page_news*/

/*single_news*/
@media(max-width: 1479px) and (min-width: 1200px) {
    .single_news {
        &-left {
            max-width: 805px; } } }
@media(max-width: 1199px) and (min-width: 960px) {
    .single_news {
        &-left {
            max-width: 100%;
            &_wrap {
                padding-right: 0px;
                &:before {
                    right: auto;
                    left: -50%;
                    width: calc(100% + 100%); } } }
        &-right {
            max-width: none;
            .sidebar {
                display: flex;
                flex-wrap: wrap;
                &-menu {
                    max-width: 526px;
                    ul {
                        flex-direction: column; } }
                &-img {
                    padding-left: 50px; } }
            &-back {
                width: 100%; } } } }
@media(max-width: 959px) {
    .single_news {
        &-left {
            max-width: 100%;
            &_wrap {
                padding-right: 0px;
                &:before {
                    right: auto;
                    left: -50%;
                    width: calc(100% + 100%); } } }
        &-right {
            max-width: none;
            .sidebar {
                display: flex;
                flex-wrap: wrap;
                &-menu {
                    max-width: 100%;
                    width: 100%;
                    ul {
                        flex-direction: column; } } }
            &-back {
                width: 100%; } } } }
/*single_news*/

/*page_partn*/
@media(max-width: 1479px) and (min-width: 1200px) {
    .page_partn {
        &-advan {
            &-left {
                &:before {
                    left: -136px; } } } } }

@media(max-width: 1199px) {
    .page_partn {
        &-advan {
            &-left {
                max-width: none;
                &:before {
                    width: calc(100% + 60px);
                    left: -30px; } }
            &-right {
                max-width: none; }
            &-bl {
                padding-left: 0; } }
        &-steps {
            &-left {
                max-width: none; }
            &-bl {
                max-width: none; }
            &-right {
                max-width: none;
                &:before {
                    left: -50%;
                    width: calc(100% + 100%); } }
            &-form {
                &_wrap {
                    padding-left: 0; } } } } }
/*page_partn*/

/*page_cont*/
@media(max-width: 1479px) and (min-width: 1200px) {
    .page_cont {
        &-map {
            width: 600px; } } }
@media(max-width: 1199px) {
    .page_cont {
        &-container {
            &:nth-child(2n) {
                .row {
                    flex-direction: row; }
                .page_cont-bl {
                    padding-left: 0; } } }
        &-form {
            .form-cont {
                &-col {
                    max-width: none; } } }
        &-left, &-right {
            max-width: none; }
        &-right {
            height: auto; }
        &-bl {
            padding-right: 0; }
        &-map {
            position: static;
            margin: 0 -30px;
            width: calc(100% + 60px); } } }
@media(max-width: 639px) {
    .page_cont {
        &-right {
            & > div {
                margin: 0 -30px; } }
        &-bl {
            &-phn {
                a {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 120%; } } } } }

/*page_cont*/

/*page_tech*/
@media(min-width: 1200px) {
    .page_tech {
        &-container {
            &:nth-child(2n) {
                .row {
                    flex-direction: row-reverse; }
                .demo-section-bl:before {
                    left: auto;
                    right: 0; }
                .demo-section-txt {
                    max-width: none;
                    padding-left: 50px; } } } } }

@media(max-width: 1199px) {
    .page_tech {
        &-info {
            overflow: hidden;
            &-left {
                max-width: none;
                &:before {
                    left: -50%;
                    width: calc(100% + 100%); } }
            &-form {
                display: flex;
                justify-content: center; }
            &-bl {
                padding: 0;
                margin: 0 auto; }
            &-right {
                max-width: none; }
            &-form {
                margin: 0 -30px;
                flex-direction: column;
                padding: 0 30px; } } } }

@media(max-width: 639px) {
    .page_tech {
        &-info {
            &-phn {
                a {
                    font-size: 24px;
                    line-height: 120%; } } } } }
/*page_tech*/

/*footer*/
@media (max-width: 639px) {
    .f {
        &-cont {
            &-phn {
                a {
                    font-size: 24px;
                    line-height: 120%; } } } } }

@media (max-width: 959px) {
    .f {
        &-cont, &-logo {
            width: 100%;
            max-width: none; }
        &-btn, &-copy {
            max-width: none; }
        &-copy {
            order: 4; }
        &-btn {
            margin-bottom: 43px; } } }
/*footer*/
